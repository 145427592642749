import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
registerLocale('nl', nl);
setDefaultLocale('nl');

// export const DatePickerGlobalStyle = createGlobalStyle`
//  .react-datepicker-popper {
//         z-index: 100;
//     }
// `;

const Container = styled.span`
    & .react-datepicker-popper {
        z-index: 100;
    }
`;

const DateLabel = styled.label`
    margin-right: 0.4em;
`;

const DatePicker = ({ style, className, label, ...props }) => {
    return (
        <Container style={style} className={className}>
            <DateLabel>{label}</DateLabel>
            <ReactDatePicker
                // onSelect={(...args) => {
                //     console.log('onSelect', args);
                // }} //when day is clicked
                // onChange={handleChangeDateFrom} //only when value has changed
                locale="nl"
                dateFormat="P"
                showIcon
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                {...props}
            />
        </Container>
    );
};
export default DatePicker;
