import { CancelablePromise } from './backend';

export const dummyFetch = (data) => {
    return new CancelablePromise((resolve, reject) => {
        resolve(data);
    });
};

export const fetchMainEvents = () => {
    return dummyFetch([
        {
            label: 'Overstromingen Limburg 2021',
            id: 'Overstromingen Limburg 2021',
        },
        { label: 'Droogte 2022', id: 'Droogte 2022' },
    ]);
};
