import { Cluster as ClusterSource } from 'ol/source';

function cluster({ source, distance = 100, minDistance = 10 }) {
    return new ClusterSource({
        source,
        distance,
        minDistance,
    });
}

export default cluster;
