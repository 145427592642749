import styled from 'styled-components';
import MainSwitch from './MainSwitch';
import useStore, { actions } from '../../store/useStore';
import { filterMainOptions, filterMainStates } from '../../store/constants';
import { water } from '../../style/colors';
import MainEvents from './MainEvents';
import DataFilters from './DataFilters';
import SelectedLocation from './SelectedLocation';

const MainContainer = styled.div`
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

const FilterContainer = styled.div`
    padding: 8px;
    border: 1px solid ${water};
    box-sizing: border-box;
    flex: 1 1;
`;

const Filters = () => {
    const { store, dispatch } = useStore();

    const handleChangeMainSwitch = (newValue) => {
        dispatch(actions.setMainFilterState(newValue));
    };

    return (
        <MainContainer>
            <MainSwitch
                value={store.filters.main}
                onChange={handleChangeMainSwitch}
                options={filterMainOptions}
            />
            <FilterContainer>
                {store.filters.main === filterMainStates.climateEvents && (
                    <MainEvents />
                )}
                {store.filters.main === filterMainStates.searchData && (
                    <DataFilters />
                )}

                <SelectedLocation />
            </FilterContainer>
        </MainContainer>
    );
};

export default Filters;
