export const droogte = '#ffa600';
export const water = '#6397dd';

export const orange = `#ff9933`;

export const uiPrimaryColor = water;
// export const uiPrimaryColor = '#68a7ab';

//Chloe:
export const blueGreen = '#68a7ab';
export const lightYellow = '#ede9de';

export function contrastingColor(color) {
    return luma(color) >= 165 ? '#000' : '#fff';
}

function luma(color) {
    if (!color || typeof color !== 'string' || color.length < 4) {
        throw 'Invalid color: ' + color;
    }
    let rgb;
    if (color.startsWith('#')) {
        rgb = hexToRGBArray(color.slice(1));
    } else if (color.startsWith('rgb')) {
        rgb = color
            .slice(color.indexOf('('), color.indexOf(')'))
            .split(',')
            .map((s) => parseInt(s, 10));
    }

    return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
}

function hexToRGBArray(color) {
    if (color.length === 3)
        color =
            color.charAt(0) +
            color.charAt(0) +
            color.charAt(1) +
            color.charAt(1) +
            color.charAt(2) +
            color.charAt(2);
    else if (color.length < 6) throw 'Invalid hex color: ' + color;
    var rgb = [];
    for (var i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
}
