import { useContext, useEffect } from 'react';
import MapContext from '../Map/MapContext';

const AbstractControl = ({ olControl }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        const element = olControl.element;
        if (element) {
            element.addEventListener(
                'mouseover',
                (evt) => {
                    evt.stopPropagation();
                },
                false
            );
        }

        map.controls.push(olControl);

        return () => map.controls.remove(olControl);
    }, [map]);

    return null;
};

export default AbstractControl;
