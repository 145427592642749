import { useContext, useEffect, useState } from 'react';
import MapContext from '../Map/MapContext';
import OLImageLayer from 'ol/layer/Image';

const ImageLayer = ({ source, extent, zIndex = 0, opacity = 1 }) => {
    const { map } = useContext(MapContext);
    const [olLayer, setOlLayer] = useState();

    useEffect(() => {
        if (!map) return;

        const imageLayer = new OLImageLayer({
            source,
            extent,
            // zIndex,
        });

        map.addLayer(imageLayer);
        // tileLayer.setZIndex(zIndex);
        setOlLayer(imageLayer);

        return () => {
            if (map) {
                map.removeLayer(imageLayer);
            }
        };
    }, [map, extent, source]);

    useEffect(() => {
        if (olLayer) {
            olLayer.setOpacity(opacity);
        }
    }, [opacity, olLayer]);

    useEffect(() => {
        if (olLayer) {
            olLayer.setZIndex(zIndex);
        }
    }, [opacity, zIndex, olLayer]);
    return null;
};

export default ImageLayer;
