export const isset = (param) => {
    return !(typeof param === 'undefined' || param === null);
};

export const isEmpty = (param) => {
    if (param instanceof Date) {
        return isNaN(param);
    }
    return (
        !isset(param) ||
        param === '' ||
        (Array.isArray(param) && param.length === 0) ||
        (typeof param === 'object' && Object.keys(param).length === 0)
    );
};

// const openLink = (url) => {
//     window.open(url, '_blank');
// };
