import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { unByKey } from 'ol/Observable';
import MapContext from '../Map/MapContext';

const OnClickEvent = ({
    onClickFunc,
    onClickStart,
    onClickFinal,
    layerFilter,
}) => {
    const { map } = useContext(MapContext);
    const onClickRef = useRef();
    const onClickStartRef = useRef();
    const onClickFinalRef = useRef();
    const layerFilterRef = useRef();
    const clickId = useRef();

    useEffect(() => {
        onClickRef.current = onClickFunc;
    }, [onClickFunc]);
    useEffect(() => {
        onClickStartRef.current = onClickStart;
    }, [onClickStart]);
    useEffect(() => {
        onClickFinalRef.current = onClickFinal;
    }, [onClickFinal]);
    useEffect(() => {
        layerFilterRef.current = layerFilter;
    }, [layerFilter]);

    useEffect(() => {
        let clickFinalization;
        let mounted = true;
        if (!map) return;

        const clickHandler = async function (evt) {
            const functionClickId = Date.now();
            clickId.current = functionClickId;
            onClickStartRef.current?.();
            clickFinalization = onClickFinalRef.current;
            // const { shiftKey, ctrlKey } = evt.originalEvent;
            const { coordinate } = evt;
            // const mapProjection = map.getView().getProjection().getCode();
            // const viewResolution = map.getView().getResolution();

            // const zoom = map.getView().getZoom();
            // let epsg;
            // if (mapProjection) {
            //     const splitProjection = mapProjection.split(':');
            //     if (
            //         splitProjection &&
            //         splitProjection.length === 2 &&
            //         splitProjection[0].toUpperCase() === 'EPSG'
            //     ) {
            //         epsg = splitProjection[1];
            //     }
            // }

            const feature = map.forEachFeatureAtPixel(
                evt.pixel,
                function (feature) {
                    return feature;
                },
                {
                    layerFilter: layerFilterRef.current,
                }
            );

            clickFinalization = null;
            if (mounted) {
                // Only invoke callback when there has not been a new click in the meantime
                // and the click event is still active:
                if (clickId.current === functionClickId) {
                    onClickFinalRef.current?.();
                    // const location = feature.get('location');
                    const properties = feature?.getProperties();
                    onClickRef.current?.({
                        properties,
                        feature,
                        coordinate,
                        map,
                    });
                }
            }
        };

        const handlerKey = map.on('singleclick', clickHandler);

        return () => {
            // if (clickHandler && map) map.un(clickHandler);
            mounted = false;
            clickFinalization?.();
            if (handlerKey) {
                unByKey(handlerKey);
            }
        };
    }, [map]);

    return null;
};

OnClickEvent.propTypes = {
    /**
     * async callback function for the click event
     * param: {
     *  properties,
     *  feature,
     *  coordinate,
     *  viewResolution,
     *
     * }
     * Does not fire when a new click is detected during async actions
     */
    onClickFunc: PropTypes.func,
    /**
     * fires directly when clicked
     */
    onClickStart: PropTypes.func,
    /**
     * onClickFinal fires when async actions are ready (just before onClick)
     * or when the clickHandler unmounts (layer changes)
     * but only when there is no new click is detected
     */
    onClickFinal: PropTypes.func,
    /**
     * if true a call is made to get the feature info from the wms source
     */
    getFeatureInfo: PropTypes.bool,
};

export default OnClickEvent;
