import styled from 'styled-components';
import MapViewer from '../MapViewer';
import Filters from '../Filters';
import DataViewer from '../DataViewer';
import ReactSplitPane from 'react-split-pane';
import HomeButton from '../HomeButton';

const InputOutputBlockWrapper = styled.div`
    height: 100%;
    position: relative;
`;

const InputOutputBlock = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const InputBlock = styled.div`
    flex: 1;
`;

const OutputBlock = styled.div`
    flex: 2;
    padding: 4px 8px;
    min-height: 0;
`;

const SplitPane = styled(ReactSplitPane)`
    & .Resizer {
        background: #000;
        opacity: 0.2;
        z-index: 1;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-background-clip: padding;
        -webkit-background-clip: padding;
        background-clip: padding-box;
    }

    & .Resizer:hover {
        -webkit-transition: all 2s ease;
        transition: all 2s ease;
    }

    & .Resizer.horizontal {
        height: 11px;
        margin: -5px 0;
        border-top: 5px solid rgba(255, 255, 255, 0);
        border-bottom: 5px solid rgba(255, 255, 255, 0);
        cursor: row-resize;
        width: 100%;
    }

    & .Resizer.horizontal:hover {
        border-top: 5px solid rgba(0, 0, 0, 0.5);
        border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }

    & .Resizer.vertical {
        width: 11px;
        margin: 0 -5px;
        border-left: 5px solid rgba(255, 255, 255, 0);
        border-right: 5px solid rgba(255, 255, 255, 0);
        cursor: col-resize;
    }

    & .Resizer.vertical:hover {
        border-left: 5px solid rgba(0, 0, 0, 0.5);
        border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
    & .Resizer.disabled {
        cursor: not-allowed;
    }
    & .Resizer.disabled:hover {
        border-color: transparent;
    }
`;

const MainView = () => {
    return (
        <SplitPane
            split="vertical"
            minSize={100}
            defaultSize={'30%'}
            //   defaultSize={parseInt(localStorage.getItem('splitPos'), 10)}
            //   onChange={(size) => localStorage.setItem('splitPos', size)}
        >
            <MapViewer />
            <InputOutputBlockWrapper>
                <HomeButton />
                <InputOutputBlock>
                    <InputBlock>
                        <Filters />
                    </InputBlock>
                    <OutputBlock>
                        <DataViewer />
                    </OutputBlock>
                </InputOutputBlock>
            </InputOutputBlockWrapper>
        </SplitPane>
    );
};

export default MainView;
