import { format, addDays } from 'date-fns';
import { createLayerSources, parseResourceUrl } from './mapserverHelpers';
import imageWMS from '../components/OpenLayers/Source/imageWMS';
import knmiRainLegend from './knmi-rainLegend.png';

const mapServer = 'https://kim.containers.wur.nl/mapserver/?map=/etc/mapserver';
// const knmiWms = 'https://wms-viewer.dataplatform.knmi.nl/wms/adaguc-server?DATASET=Rd1&SERVICE=WMS&&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&LAYERS=precipitation&WIDTH=1858&HEIGHT=653&CRS=EPSG%3A3857&BBOX=-243075.3536030138,6541612.44574923,1425719.2263950137,7128115.60007577&STYLES=PRSUMD%2Fshadedcontour&FORMAT=image/png&TRANSPARENT=TRUE&&TIME=2023-12-09T08%3A00%3A00Z';
const knmiWms =
    'https://anonymous.api.dataplatform.knmi.nl/wms/adaguc-server?DATASET=Rd1&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&STYLES=PRSUMD%2Fshadedcontour&FORMAT=image/png&TRANSPARENT=TRUE';

/**
 *
 * @param {Date} date
 * @returns {Promise}
 */
export const rainfallMapMapserver = (date) => {
    const nextDay = addDays(date, 1);
    const year = format(nextDay, 'yyyy');
    const datepart = format(nextDay, 'yyyyMMdd');
    const map = `RAINFALL${year}`;
    const layer = `RAD_NL25_RAC_MFBS_24H_${datepart}0800`;
    const onlineresource = `${mapServer}/${map}.map&SERVICE=WMS&VERSION=1.3.0`;

    return createLayerSources([
        { onlineresource, name: layer, protocol: 'wms' },
    ]).then((initializedLayers) => {
        const [layerWithSource] = initializedLayers;
        return layerWithSource;
    });
};

// const knmiApiKey = '***';
// function customLoader(tile, src) {
//     let client = new XMLHttpRequest();
//     // let client = new Request();
//     client.withCredentials = true;
//     client.open('GET', src);
//     client.setRequestHeader('Authorization', knmiApiKey);
//     client.onload = function () {
//         tile.getImage().src = src;
//     };
//     client.send();
// }

/**
 *
 * @param {Date} date
 * @returns {Promise}
 */
export const rainfallMap = (date) => {
    const datepart = format(date, 'yyyy-MM-dd');
    const layer = `precipitation`;
    const onlineresource = `${knmiWms}&TIME=${datepart}T08:00:00Z`;
    const layerObject = {
        onlineresource,
        name: layer,
        protocol: 'wms',
        // tileLoadFunction: customLoader,
    };

    let options = parseResourceUrl(layerObject.onlineresource, {
        layers: layerObject.name,
    });

    const source = imageWMS({
        ...options,
        attributions:
            'weerdata: <a href="https://dataplatform.knmi.nl/" target="_blank">knmi</a>',
    });

    return Promise.resolve({
        ...layerObject,
        source,
        legendUrl: knmiRainLegend,
    });
};

/**
 *
 * @param {Date} date
 * @returns {Promise}
 */
// export const tiledRainfallMap = (date) => {
//     //const dayBefore = addDays(date, -1);
//     const datepart = format(date, 'yyyy-MM-dd'); //TIME=2023-12-09T08%3A00%3A00Z
//     const layer = `precipitation`;
//     const onlineresource = `${knmiWms}&TIME=${datepart}T08:00:00Z`;

//     return createLayerSources([
//         {
//             onlineresource,
//             name: layer,
//             protocol: 'wms',
//             options: {
//                 attributions:
//                     'weerdata: <a href="https://dataplatform.knmi.nl/" target="_blank">knmi</a>',
//                 // tileLoadFunction: customLoader,
//             },
//         },
//     ]).then((initializedLayers) => {
//         const [layerWithSource] = initializedLayers;
//         return layerWithSource;
//     });
// };
