import styled from 'styled-components';
import { orange } from '../../style/colors';

// const Checkmark = () => <span style={{ marginRight: '0.5em' }}>&#x2714;</span>;

const MainContainer = styled.div`
    padding: 4px 2em 4px 0.8em;
    border-radius: 999px;
    background: ${(props) => (props.selected ? orange : '#fff')};
    color: #000;
    cursor: pointer;
    border: 2px solid ${orange};
    user-select: none;

    &:before {
        content: '✔';
        display: inline-block;
        width: 1em;
        margin-right: 0.2em;
        visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
    }
`;

const FilterOption = ({ label, selected, ...props }) => {
    return (
        <MainContainer selected={selected} {...props}>
            {label}
        </MainContainer>
    );
};

export default FilterOption;
