import styled from 'styled-components';
import { isset } from '../../helpers/general';
import useStore, { actions } from '../../store/useStore';
import { uiPrimaryColor } from '../../style/colors';
import { getDateString } from '../../helpers/data';

const SelectedLocationContainer = styled.div`
    border: 4px solid ${uiPrimaryColor};
    padding: 8px 16px;
    margin-top: 8px;
    background: ${uiPrimaryColor}33;
    display: flex;
    gap: 16px;
`;

const ContentContainer = styled.div`
    flex: 1;
`;

const Label = styled.span`
    font-style: italic;
    font-size: 0.9rem;
`;

const SelectedLocation = () => {
    const { store, dispatch } = useStore();
    const { selectedEventLocation } = store;

    const deselect = () => {
        dispatch(actions.clearEventLocationsSelection());
    };

    if (!isset(selectedEventLocation.eventLocationId)) {
        return null;
    }

    const properties = selectedEventLocation.data?.properties;
    return (
        <SelectedLocationContainer>
            <div>
                <button onClick={deselect}>herstel</button>
            </div>
            <ContentContainer>
                <span style={{ textDecoration: 'underline' }}>
                    geselecteerde locatie:
                </span>
                {/* ({selectedEventLocation.eventLocationId}) */}
                <br />
                {properties && (
                    <>
                        <Label>locatie:</Label>{' '}
                        {properties.location_name
                            ? `${properties.location_name}, ${properties.municipality}`
                            : properties.municipality}
                        ; <i>({properties.location_description})</i>
                        <br />
                        <Label>datum:</Label>{' '}
                        {getDateString({
                            startdatum: properties.startdate,
                            einddatum: properties.enddate,
                            jaar_van: properties.jaar_van,
                            jaar_tot: properties.jaar_tot,
                        })}
                        <br />
                        <Label>type:</Label> {properties.climate_impact_event}
                    </>
                )}
            </ContentContainer>
        </SelectedLocationContainer>
    );
};

export default SelectedLocation;
