import styled, { keyframes } from 'styled-components';
import { uiPrimaryColor } from '../../style/colors';

const barHeight = 4;
const ProgressContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    border: 4px solid transparent;
    position: relative;
    padding: ${barHeight / 2}px;
    box-sizing: border-box;

    &:before {
        content: '';
        border: 1px solid #fff;
        border-radius: 10px;
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
    }
`;

const loaderBarAnimation = keyframes`
    0% {
        left:0%;
        right:100%;
        width:0%;
      }
      10% {
        left:0%;
        right:75%;
        width:25%;
      }
      90% {
        right:0%;
        left:75%;
        width:25%;
      }
      100% {
        left:100%;
        right:0%;
        width:0%;
      }
`;

const LoaderBar = styled.div`
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: ${uiPrimaryColor};
    width: 0;
    animation: ${loaderBarAnimation} 2s linear infinite;
`;
const Progress = ({ ...props }) => {
    return (
        <ProgressContainer {...props}>
            <LoaderBar />
        </ProgressContainer>
    );
};

export default Progress;
