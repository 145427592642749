import styled from 'styled-components';
import { isEmpty } from '../../helpers/general';
import { uiPrimaryColor } from '../../style/colors';

const KenmerkContainer = styled.div`
    margin-bottom: 0.3em;
`;

const Label = styled.span`
    font-style: italic;
    font-size: 0.9rem;
    color: ${uiPrimaryColor};
`;

const Kenmerk = ({ label, content, split = false, format }) => {
    if (isEmpty(content)) {
        return null;
    }

    if (!split) {
        return (
            <KenmerkContainer>
                <Label>{label}:</Label> {format ? format(content) : content}
            </KenmerkContainer>
        );
    }

    const contentArray = content
        .split(';')
        .map((l) => l.trim())
        .filter((l) => l);

    let appropriateLabel;

    if (contentArray.length < 2) {
        appropriateLabel = Array.isArray(label)
            ? (appropriateLabel = label[0])
            : label;
        return (
            <KenmerkContainer>
                <Label>{appropriateLabel}:</Label>{' '}
                {format ? format(content) : content}
            </KenmerkContainer>
        );
    }
    appropriateLabel = Array.isArray(label)
        ? (appropriateLabel = label[1])
        : label;
    return (
        <KenmerkContainer>
            <Label>{appropriateLabel}:</Label>{' '}
            <ul style={{ marginTop: 2, marginBottom: 2 }}>
                {contentArray.map((singleStr, i) => {
                    return (
                        <li key={i}>
                            {format ? format(singleStr) : singleStr}
                        </li>
                    );
                })}
            </ul>
        </KenmerkContainer>
    );
};
export default Kenmerk;
