import { fromLonLat } from 'ol/proj';

import { Projection, addProjection, addCoordinateTransforms } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

export const startingPoint = [5.2913, 52.1326];
export const defaultMapProjection = 'EPSG:3857';
export const tooltipProjection = 'EPSG:28992';
export const center = fromLonLat(startingPoint, defaultMapProjection);
export const initialZoom = 7;
export const minZoom = 7;
export const maxZoom = 20;

/**
 * EPSG:28992 = RD coordinates. We need to add the projection definition
 * and transform functions for it to be able to plot/highlight them on the map:
 *
 * src: https://epsg.io/28992
 */
// rijksdriehoeksstelsel / dutch projection
const initProjections = () => {
    proj4.defs(
        'EPSG:28992',
        '+proj=sterea +lat_0=52.15616055555555   +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000  +ellps=bessel  +towgs84=565.040,49.910,465.840,-0.40939,0.35971,-1.86849,4.0772 +units=m +no_defs'
    );

    const dutchProjection = new Projection({
        code: 'EPSG:28992',
        // The extent is used to determine zoom level 0. Recommended values for a
        // projection's validity extent can be found at https://epsg.io/.
        extent: [-285401.92, 22598.08, 595402.0, 903402.0],
        worldExtent: [3.2, 50.75, 7.22, 53.7],
        units: 'm',
    });
    addProjection(dutchProjection);
    register(proj4);

    addCoordinateTransforms(
        defaultMapProjection,
        dutchProjection,
        function (coordinate) {
            return proj4(defaultMapProjection, tooltipProjection, coordinate);
        },
        function (coordinate) {
            return proj4(tooltipProjection, defaultMapProjection, coordinate);
        }
    );
};
initProjections();
