import { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { Attribution } from 'ol/control';
import AbstractControl from './Control';

export const attributionStyle = css`
    &.ol-uncollapsible button {
        display: none;
    }

    & ul {
        margin: 0;
        padding: 1px 0.5em;
        color: #333;
        text-shadow: 0 0 2px #fff;
        font-size: 12px;
    }

    & li {
        list-style: none;
    }

    & a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: blue;
            text-decoration: underline;
        }
    }
`;

/*
&.ol-attribution {
        text-align: right;
        bottom: 0.5em;
        right: 0.5em;
        max-width: calc(100% - 1.3em);
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
    }

    &.ol-attribution a {
        color: var(--ol-subtle-foreground-color);
        text-decoration: none;
    }

    &.ol-attribution ul {
        margin: 0;
        padding: 1px 0.5em;
        font-size: 12px;
    }

    &.ol-attribution li {
        display: inline;
        list-style: none;
    }

    &.ol-attribution li:not(:last-child):after {
        content: ' ';
    }

    &.ol-attribution img {
        max-height: 2em;
        max-width: inherit;
        vertical-align: middle;
    }

    &.ol-attribution button {
        flex-shrink: 0;
    }

    &.ol-attribution.ol-collapsed ul {
        display: none;
    }

    &.ol-attribution.ol-uncollapsible {
        bottom: 0;
        right: 0;
        border-radius: 4px 0 0;
    }

    &.ol-attribution.ol-uncollapsible img {
        margin-top: -0.2em;
        max-height: 1.6em;
    }

    &.ol-attribution.ol-uncollapsible button {
        display: none;
    }
*/

const AttributionControl = ({ collapsible = false }) => {
    const [olControl, setOlControl] = useState(null);

    useEffect(() => {
        setOlControl(
            new Attribution({
                collapsible,
            })
        );
    }, [collapsible]);

    return olControl && <AbstractControl olControl={olControl} />;
};

export default AttributionControl;
