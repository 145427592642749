import styled from 'styled-components';

const StyledFieldset = styled.fieldset``;
const StyledLegend = styled.legend``;

const Fieldset = ({ label, children }) => {
    return (
        <StyledFieldset>
            {label && <StyledLegend>{label}</StyledLegend>}
            {children}
        </StyledFieldset>
    );
};

export default Fieldset;
