import { Circle, Fill, Stroke, Style, Icon, Text } from 'ol/style';
import { asArray, asString } from 'ol/color';
import markerIcon from './marker.svg';
import markerIconHL from './marker-hl.svg';
import { eventTypeColors, eventTypes } from '../../services/events';

const strokeStyle = new Stroke({
    color: 'blue',
    width: 2,
});

const fillStyle = new Fill({
    color: 'rgba(0, 0, 255, 0.5)',
});

const polygonStyle = new Style({
    stroke: strokeStyle,
    fill: fillStyle,
});

const pointStyle = new Style({
    image: new Icon({
        crossOrigin: 'anonymous',
        color: 'blue',
        anchor: [0.5, 40],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: markerIcon,
    }),
});

const baseGeometryStyles = {
    Point: pointStyle,
    LineString: new Style({
        stroke: strokeStyle,
    }),
    MultiLineString: new Style({
        stroke: strokeStyle,
    }),
    MultiPoint: pointStyle,
    MultiPolygon: polygonStyle,
    Polygon: polygonStyle,
    GeometryCollection: {
        ...polygonStyle,
        ...pointStyle,
    },
    Circle: polygonStyle,
};

const getIconColor = (climateImpactEvent, opacity = 1) => {
    if (opacity === 1) {
        return eventTypeColors[climateImpactEvent];
    }

    const colorArray = asArray(eventTypeColors[climateImpactEvent]);
    colorArray[3] = opacity;
    return asString(colorArray);
};

export const locationIconfeatureStyle = function (feature) {
    const climateImpactEvent = feature.get('climate_impact_event');

    const pointStyle = new Style({
        image: new Icon({
            crossOrigin: 'anonymous',
            color: getIconColor(climateImpactEvent),
            anchor: [0.5, 40],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: markerIconHL,
        }),
    });

    const geometryStyles = {
        ...baseGeometryStyles,
        Point: pointStyle,
    };

    const geometryType = feature.getGeometry().getType();
    return geometryStyles[geometryType];
};

/**
 * We use a scaling factor to make the circles larger when zoomed in. You can adjust the scaling
 * function by altering two helper variables:
 * - strength, default = 1, higher makes the influence of the zoom level
 * stronger. 0 means no influence (circle size will be independent of zoom)
 * - baseSize, default = 4 To determine the base size of the circles. When zoomed out this will
 * be the radius of a 'cluster' (circle) with 1 feature.
 */
const lineWidth = 1;
const baseSize = 5;
const scalingFactor = (resolution, strength = 1) => {
    return strength * (10.8 / Math.pow(resolution, 1 / 3) - 1) + 1;
};

export const singleFeatureStyle = function (
    feature,
    resolution,
    highlight = false
) {
    const scale = scalingFactor(resolution);
    const radius = scale * baseSize;

    const climateImpactEvent = feature.get('climate_impact_event');

    const style = {
        image: new Circle({
            radius: radius,
            stroke: new Stroke({
                color: highlight ? '#ff0' : '#fff',
                width: highlight ? 4 : lineWidth,
            }),
            fill: new Fill({
                color: getIconColor(climateImpactEvent),
            }),
        }),
    };

    return new Style(style);
};

export const highlightedFeatureStyle = function (feature, resolution) {
    return singleFeatureStyle(feature, resolution, true);
};

const rad = (fraction) => (fraction - 0.25) * 2 * Math.PI;
const drawArc = (
    context,
    radius,
    start,
    end,
    fill,
    lineWidth = 1,
    lineColor = '#000'
) => {
    if (start === end) {
        return;
    }
    context.beginPath();
    if (start === 0 && end === 1) {
        context.moveTo(0, -radius);
    } else {
        context.moveTo(0, 0);
    }
    context.arc(0, 0, radius, rad(start), rad(end));
    context.closePath();
    context.fillStyle = fill;
    context.fill();
    context.strokeStyle = lineColor;
    if (lineWidth > 0) {
        context.lineWidth = lineWidth;
        context.stroke();
    }
};

/**
 * Draw pie charts for clustered items
 * Size (surface) of total pie is proportional to the number of features
 * in the cluster. Also the total number of features is shown inside the circle (pie)
 * Slices represent the (event) types of the features
 *
 * @param {*} feature
 * @param {*} resolution
 * @returns {*} Style
 */
export const clusterStyle = function (feature, resolution) {
    /**
     * We use a scaling factor to make the circles larger when zoomed in. You can adjust the scaling
     * function by altering two helper variables:
     * - resolutionDependentScale, default = 1, higher makes the influence of the zoom level
     * stronger. 0 means no influence (circle size will be independent of zoom)
     * - baseSize, default = 4 To determine the base size of the circles. When zoomed out this will
     * be the radius of a 'cluster' (circle) with 1 feature.
     */

    const features = feature.get('features');
    const length = features.length;

    if (length === 1) {
        return singleFeatureStyle(features[0], resolution);
    }

    const count = features.reduce((prev, f) => {
        const climateImpactEvent = f.get('climate_impact_event');
        return {
            ...prev,
            [climateImpactEvent]: (prev[climateImpactEvent] ?? 0) + 1,
        };
    }, {});

    const scale = scalingFactor(resolution);
    const radius = scale * baseSize * Math.sqrt(Math.min(length, 100));
    const size = radius * 2 + lineWidth + 2;

    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');
    context.translate(size / 2, size / 2);
    let start = 0;
    for (const { value: evType } of eventTypes) {
        const end = start + (count[evType] ?? 0) / length;
        drawArc(
            context,
            radius,
            start,
            end,
            getIconColor(evType),
            lineWidth,
            length > 1 ? '#000' : '#fff'
        );
        start = end;
    }

    const style = {
        image: new Icon({
            img: canvas,
            imgSize: [size, size],
            // scale,
        }),
    };
    // draw the total number of features inside the circle (but not
    // when there is just one)
    // if (length > 1) {
    //     style.text = new Text({
    //         text: length.toString(),
    //         fill: new Fill({
    //             color: '#fff',
    //         }),
    //     });
    // }
    return new Style(style);
};

// export const featureStyle = function (feature) {
//     const climateImpactEvent = feature.get('climate_impact_event');

//     const pointStyle = new Style({
//         image: new Icon({
//             crossOrigin: 'anonymous',
//             color: getIconColor(climateImpactEvent),
//             anchor: [0.5, 40],
//             anchorXUnits: 'fraction',
//             anchorYUnits: 'pixels',
//             src: markerIcon,
//         }),
//     });

//     const geometryStyles = {
//         ...baseGeometryStyles,
//         Point: pointStyle,
//     };

//     const geometryType = feature.getGeometry().getType();
//     return geometryStyles[geometryType];
// };
