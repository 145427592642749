import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { fetchLocationImpacts } from './backend';
import { filterMainStates } from '../store/constants';

export const dataStates = {
    initializing: 0,
    ready: 1,
    fetching: 2,
    partially: 3,
    complete: 4,
    aborted: 5,
};

const PAGESIZE = 10;
const useImpactsData = (initialFilters = {}) => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState(initialFilters);
    const [pageData, setPageData] = useState(0);

    const status = useRef(dataStates.initializing);

    // obsolete
    const afterFetchFiltering = useCallback((impact) => {
        return true;
    }, []);

    // const afterFetchSorting = useCallback((impactA, impactB) => {
    //     const { startdatum: startA, einddatum: eindA } = impactA;
    //     const { startdatum: startB, einddatum: eindB } = impactB;
    //     if (!startA) {
    //         return -1;
    //     }
    //     if (!startB) {
    //         return 1;
    //     }
    //     return `${startA}${eindA}`.localeCompare(`${startB}${eindB}`);
    // }, []);

    const fetchPage = useCallback(
        (page) => {
            if (page < 1) {
                return;
            }
            status.current = dataStates.fetching;
            let fetchFilters = {
                pagenumber: page,
                pagesize: PAGESIZE,
            };
            if (filters.locationId) {
                //obsolete
                fetchFilters.locationId = filters.locationId;
            } else {
                if (filters.main === filterMainStates.climateEvents) {
                    fetchFilters = {
                        ...fetchFilters,
                        group: filters.mainEvent?.id,
                    };
                } else {
                    let dateFrom = new Date(1900, 0, 1);
                    let dateTo = new Date();
                    if (filters.date?.from) {
                        dateFrom = filters.date.from;
                    }
                    if (filters.date?.to) {
                        dateTo = filters.date.to;
                    }
                    fetchFilters = {
                        ...fetchFilters,
                        eventTypes: filters.eventTypes,
                        provenceCode: filters.location?.provence?.value,
                        municipality: filters.location?.municipality?.label,
                        dateFrom,
                        dateTo,
                    };
                }
            }

            return fetchLocationImpacts(fetchFilters);
        },
        [
            filters.locationId,
            filters.main,
            filters.mainEvent,
            filters.eventTypes,
            filters.location?.provence?.value,
            filters.location?.municipality?.label,
            filters.date?.from,
            filters.date?.to,
        ]
    );

    const handleResults = useCallback((results, page) => {
        const filteredResults = results.reduce((prev, location) => {
            return [
                ...prev,
                ...location.impacts.map((impact) => ({
                    ...impact,
                    location,
                    page,
                })),
            ];
        }, []);

        if (page < 2) {
            setData(filteredResults);
        } else {
            setData((prev) => [
                ...prev,
                ...filteredResults.filter((r) =>
                    prev.every((p) => p.id !== r.id)
                ),
            ]);
        }
        setPageData(page);

        if (results.length > 0) {
            status.current = dataStates.partially;
        } else {
            status.current = dataStates.complete;
        }
    }, []);

    const handleFetchError = useCallback(
        (error, page) => {
            // status.current = dataStates.aborted;
            // handleResults([], page);
            handleResults([], 0);
            status.current = dataStates.aborted;
            console.warn(error);
        },
        [handleResults]
    );

    const fetchMore = useCallback(() => {
        if (
            status.current !== dataStates.complete &&
            status.current !== dataStates.fetching &&
            status.current !== dataStates.initializing
        ) {
            let page = pageData;
            const fetchPromise = new Promise(async (resolve, reject) => {
                const fetchedResults = [];
                let endReached = false;
                while (
                    fetchedResults.filter(afterFetchFiltering).length <
                        PAGESIZE &&
                    !endReached
                ) {
                    page++;
                    const fetched = await fetchPage(page);
                    fetchedResults.push(...fetched);
                    endReached = fetched.length < PAGESIZE;
                }
                resolve(fetchedResults);
            });
            return fetchPromise
                .then((results) => {
                    handleResults(results, page);
                })
                .catch((err) => {
                    handleFetchError(err, page);
                });
        }
    }, [
        pageData,
        fetchPage,
        handleResults,
        handleFetchError,
        afterFetchFiltering,
    ]);

    const handleSetFilters = useCallback((newFilter) => {
        setData([]);
        setPageData(0);
        setFilters(newFilter);
        status.current = dataStates.ready;
    }, []);

    const filteredData = useMemo(() => {
        return data.filter(afterFetchFiltering);
        //.sort(afterFetchSorting);
    }, [data, afterFetchFiltering]);

    useEffect(() => {
        if (data.length < PAGESIZE) {
            fetchMore();
        }
    }, [data.length, filters, fetchMore]);

    return {
        data: filteredData,
        status,
        filters,
        setFilters: handleSetFilters,
        fetchMore,
        fetching: status.current === dataStates.fetching,
    };
};

export default useImpactsData;
