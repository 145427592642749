import styled from 'styled-components';
import { lightYellow, blueGreen } from '../../style/colors';
import { Link } from 'react-router-dom';

const BlockLinkContainer = styled.a`
    display: block;
    text-decoration: none;
    color: inherit;
    background: ${lightYellow};
    padding: 16px;
    border-radius: 8px;
    width: 300px;
    height: 400px;
    border: 2px solid ${blueGreen};
`;

const BlockTitle = styled.h3`
    background: ${blueGreen};
    color: #fff;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 0;
    text-align: center;
`;

const BlockLink = ({ to, href, title, children, ...props }) => {
    return (
        <BlockLinkContainer
            href={href}
            target={to ? null : '_blank'}
            {...props}
            as={to ? Link : null}
            to={to}
        >
            <BlockTitle>{title}</BlockTitle>
            {children}
        </BlockLinkContainer>
    );
};
export default BlockLink;
