import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import OlMap from '../OpenLayers/Map';
import { osm } from '../OpenLayers/Source';
import {
    Layers,
    TileLayer,
    ImageLayer,
    VectorLayerWithChildren,
} from '../OpenLayers/Layers';
import {
    Controls,
    ScaleLineControl,
    ZoomControl,
    AttributionControl,
    styles,
    Tooltip,
} from '../OpenLayers/Controls';
import { CustomControlsWrapper } from '../OpenLayers/Controls/CustomControls';
import WeatherMapControl from './WeatherMapControl';

import { boundingExtent, getHeight, getWidth } from 'ol/extent';
// import { transform } from 'ol/proj';

import OnClickEvent from '../OpenLayers/Interaction/OnClick';
import OnMousemoveEvent from '../OpenLayers/Interaction/OnMousemove';
import {
    center,
    initialZoom,
    defaultMapProjection,
    minZoom,
    maxZoom,
} from '../OpenLayers/config';
import useStore, { actions } from '../../store/useStore';
import useDimensions from '../../helpers/useDimensions';

import { clusterStyle, highlightedFeatureStyle } from './style';
import { isset } from '../../helpers/general';

import ZoomToLocation from './ZoomToLocation';
import MaskLayer from '../OpenLayers/Layers/MaskLayer';

import {
    rainfallMap as getRainfallMap,
    rainfallMapMapserver as getRainfallMapMapserver,
} from '../../services/mapLayers';
import { compileFeatureInfoResponse } from '../../services/knmi';
import RainfallTooltipContent from './RainfallTooltipContent';
import { getLegendUrl } from '../../services/mapserverHelpers';

const openStreetMap = osm();

const StyledMap = styled(OlMap)`
    height: 100%;
    position: relative;

    & .ol-full-screen {
        top: 8px;
        right: 8px;
    }

    & .ol-mouse-position {
        position: absolute;
        bottom: 8px;
        left: 8px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        padding: 2px;
    }

    & .ol-zoom {
        top: 8px;
        right: 8px;
    }

    & .ol-attribution {
        bottom: 0;
        left: 0;

        ${styles.attribution}
    }
`;

export const MapContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const MapViewer = () => {
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);

    const { store, dispatch } = useStore();

    const [width, height] = useDimensions(mapContainerRef);

    const [rainfallMap, setRainfallMap] = useState();

    const [tooltipState, setTooltipState] = useState({
        show: true,
        position: { x: 0, y: 0 },
        coordinates: { x: null, y: null },
        value: null,
    });

    useEffect(() => {
        if (store.weathermapDate) {
            if (store.useMapserverWeatherMap) {
                getRainfallMapMapserver(store.weathermapDate).then((map) => {
                    setRainfallMap(map);
                });
            } else {
                getRainfallMap(store.weathermapDate).then((map) => {
                    setRainfallMap(map);
                });
            }
        } else {
            setRainfallMap(null);
        }
    }, [store.weathermapDate, store.useMapserverWeatherMap]);

    const deselect = () => {
        dispatch(
            actions.featureClicked({
                feature: null,
                properties: null,
            })
        );
        setTooltipState((prev) => ({ ...prev, show: false }));
    };

    const handleClick = (clickData) => {
        if (!clickData?.feature) {
            // deselect();
            return;
        }

        const features = clickData.feature.get('features');
        if (features?.length) {
            if (features.length === 1) {
                const feat = features[0];
                dispatch(
                    actions.featureClicked({
                        feature: feat,
                        properties: feat.getProperties(),
                    })
                );
            } else {
                deselect();
                const extent = boundingExtent(
                    features.map((r) => r.getGeometry().getCoordinates())
                );
                const dist = Math.max(getWidth(extent), getHeight(extent));
                if (dist < 5) {
                    /* We clicked on a location with multiple events (with different eventlocationids)
                    on (almost) the same coordinates. We are not able to show them as seperate dots
                    so we need to be able to select them all @todo
                    */
                    clickData.map.getView().fit(
                        extent.map((c, i) => (i < 2 ? c - 300 : c + 300)),
                        {
                            duration: 1000,
                            padding: [80, 80, 80, 80],
                        }
                    );
                } else {
                    clickData.map.getView().fit(extent, {
                        duration: 1000,
                        padding: [80, 80, 80, 80],
                    });
                }
            }
        } // else: we probably clicked an already selected feature
    };

    const handleMousemove = (move) => {
        if (move) {
            const { mousePosition, coordinate } = move;
            setTooltipState((prev) => ({
                ...prev,
                position: mousePosition,
                // coordinates: transform(
                //     coordinate,
                //     defaultMapProjection,
                //     tooltipProjection
                // ),
                coordinates: coordinate,
                value: null,
            }));
        }
    };

    const handleMousemoveData = (moveData) => {
        if (!moveData?.data) return;

        const value = compileFeatureInfoResponse(moveData.data);
        setTooltipState((prev) => ({
            ...prev,
            value,
        }));
    };

    // const closePopup = () => {
    //     dispatch(actions.closePopup());
    // };

    const selectionActive =
        isset(store.selectedEventLocation.eventLocationId) ||
        isset(store.selectedImpact.impactId);
    const hoverActive = store.hoveredImpact;

    const hoveredFeatures = isset(store.hoveredImpact)
        ? store.geoJsonFeaturesFiltered.filter((feat) => {
              const eventlocationid = feat.get('eventlocationid');
              return eventlocationid === store.hoveredImpact;
          })
        : [];

    let selectedFeatures = [];
    if (isset(store.selectedEventLocation.data?.feature)) {
        selectedFeatures.push(store.selectedEventLocation.data.feature);
    } else if (
        isset(store.selectedImpact.impactId) &&
        isset(store.selectedImpact.data?.eventlocationid)
    ) {
        selectedFeatures = store.geoJsonFeaturesFiltered.filter((feat) => {
            const eventlocationid = feat.get('eventlocationid');
            return (
                eventlocationid === store.selectedImpact.data.eventlocationid
            );
        });
    }

    const onClickLayerFilter = (layer) => {
        // onClick only for cluster layer, only when visible
        // (when rainfallMap is visible the cluster layer is hidden by opacity!)
        if (rainfallMap) {
            return false;
        }
        const layerName = layer.get('name');
        return layerName === 'clusterLayer';
    };

    return (
        <MapContainer ref={mapContainerRef}>
            <StyledMap
                ref={mapRef}
                center={center}
                zoom={initialZoom}
                minZoom={minZoom}
                maxZoom={maxZoom}
                dimensions={[width, height]}
                projection={defaultMapProjection}
            >
                <Layers>
                    {/* background layer: */}
                    <TileLayer source={openStreetMap} zIndex={0} />

                    {rainfallMap && store.useMapserverWeatherMap && (
                        <TileLayer
                            source={rainfallMap.source}
                            zIndex={1}
                            opacity={0.7}
                        />
                    )}
                    {rainfallMap && !store.useMapserverWeatherMap && (
                        <>
                            <ImageLayer
                                source={rainfallMap.source}
                                zIndex={1}
                                opacity={0.7}
                            />

                            <OnMousemoveEvent
                                layerSource={rainfallMap.source}
                                onMove={handleMousemove}
                                onData={handleMousemoveData}
                                onMouseOver={() => {
                                    setTooltipState((prev) => ({
                                        ...prev,
                                        show: true,
                                    }));
                                }}
                                onMouseOut={() => {
                                    setTooltipState((prev) => ({
                                        ...prev,
                                        show: false,
                                    }));
                                }}
                                onError={(err) => console.warn(err)}
                            />
                        </>
                    )}
                    {/* cluster layer: */}
                    {store.geoJsonFeaturesFiltered && (
                        <VectorLayerWithChildren
                            name="clusterLayer"
                            clustered
                            style={clusterStyle}
                            zIndex={90}
                            opacity={
                                rainfallMap
                                    ? 0
                                    : selectionActive || hoverActive
                                    ? 0.5
                                    : 1
                            }
                        >
                            {store.geoJsonFeaturesFiltered}
                        </VectorLayerWithChildren>
                    )}
                    {/* highlight layer (selected features): */}
                    <VectorLayerWithChildren
                        style={highlightedFeatureStyle}
                        zIndex={101}
                    >
                        {selectedFeatures}
                    </VectorLayerWithChildren>
                    {/* highlight layer (hovered (in table) features): */}
                    <VectorLayerWithChildren
                        style={highlightedFeatureStyle}
                        zIndex={101}
                    >
                        {hoveredFeatures}
                    </VectorLayerWithChildren>
                    <MaskLayer
                        zIndex={80}
                        feature={store.filterGeometry}
                    ></MaskLayer>
                    <ZoomToLocation location={store.filterGeometry} />
                </Layers>

                <Controls>
                    <ScaleLineControl />
                    <ZoomControl />
                    <AttributionControl />
                </Controls>
                <CustomControlsWrapper>
                    <WeatherMapControl
                        legendUrl={
                            rainfallMap ? getLegendUrl(rainfallMap) : null
                        }
                    />
                    <Tooltip
                        show={tooltipState.show && isset(tooltipState.value)}
                        $position={tooltipState.position}
                    >
                        <RainfallTooltipContent
                            // coordinates={tooltipState.coordinates}
                            value={tooltipState.value}
                        />
                    </Tooltip>
                </CustomControlsWrapper>

                <OnClickEvent
                    onClickFunc={handleClick}
                    layerFilter={onClickLayerFilter}
                />
            </StyledMap>
        </MapContainer>
    );
};

export default MapViewer;
