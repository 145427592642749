/**
 * @typedef {Object} TAction
 * @property {string} type
 * @property {*} payload
 */

export const setBusyState = (bstate) => ({
    type: 'setBusyState',
    payload: bstate,
});

export const setStoreParameter = (key, value) => ({
    type: 'setStoreParameter',
    payload: { key, value },
});

/**
 *
 * @param {number} value - one of the filterMainStates in constants
 * @returns {TAction}
 */
export const setMainFilterState = (value) => ({
    type: 'setMainFilterState',
    payload: value,
});

/**
 *
 * @param {Object} value - one of the filterMainStates in constants
 * @param {number | string} value.id
 * @param {string} value.label
 * @returns {TAction}
 */
export const setFilterMainEvent = (value) => ({
    type: 'setFilterMainEvent',
    payload: value,
});

/**
 * @typedef {Object} TOption
 * @property {string} label
 * @property {string | number} value
 */

/**
 *
 * @param {Object} value - one of the filterMainStates in constants
 * @param {TOption} value.provence
 * @param {TOption} value.municipality
 * @param {boolean} value.put - if true, only parameters that are present in value will be updated
 * @returns {TAction}
 */
export const setFilterLocation = (value) => ({
    type: 'setFilterLocation',
    payload: value,
});

/**
 *
 * @param {string[] | number[]} value - one of the event type values
 * @returns {TAction}
 */
export const setFilterEventTypes = (values) => ({
    type: 'setFilterEventTypes',
    payload: values,
});

/**
 *
 * @param {string | number} value - one of the event type values
 * @returns {TAction}
 */
export const toggleFilterEventType = (value) => ({
    type: 'toggleFilterEventType',
    payload: value,
});

/**
 *
 * @param {Date} newDate - Date Object
 * @returns {TAction}
 */
export const setFilterDateFrom = (newDate) => ({
    type: 'setFilterDateFrom',
    payload: newDate,
});
/**
 *
 * @param {Date} newDate - Date Object
 * @returns {TAction}
 */
export const setFilterDateTo = (newDate) => ({
    type: 'setFilterDateTo',
    payload: newDate,
});

/**
 *
 * @param {number} eventlocationid
 * @returns {TAction}
 */
export const selectEventLocationById = (eventlocationid) => ({
    type: 'selectEventLocationById',
    payload: eventlocationid,
});
/**
 *
 * @param {number} eventlocationid
 * @returns {TAction}
 */
export const selectEventLocation = (eventLocationId, data) => ({
    type: 'selectEventLocation',
    payload: { eventLocationId, data },
});

/**
 * @typedef {import('../services/backend').TImpact} TImpact
 *
 * @param {TImpact} impact
 * @returns {{type: string, payload: TImpact}}
 */
export const selectImpact = (impact) => ({
    type: 'selectImpact',
    payload: impact,
});
export const clearImpactSelection = () => ({
    type: 'clearImpactSelection',
    payload: null,
});
export const clearEventLocationsSelection = () => ({
    type: 'clearEventLocationsSelection',
    payload: null,
});
export const startFetchEventLocationsSelection = (eventlocationid) => ({
    type: 'startFetchEventLocationsSelection',
    payload: eventlocationid,
});
export const endFetchEventLocationsSelection = (eventlocationid, data) => ({
    type: 'endFetchEventLocationsSelection',
    payload: { eventlocationid, data },
});

export const hoverImpactRow = (eventlocationid) => ({
    type: 'hoverImpactRow',
    payload: eventlocationid,
});

/**
 *
 * @param {*} clickData - the data returned from the map onClick
 * @returns {TAction}
 */
export const featureClicked = (clickData) => ({
    type: 'featureClicked',
    payload: clickData,
});

export const closePopup = () => ({
    type: 'closePopup',
    payload: null,
});

export const setFilterGeometry = (value) => ({
    type: 'setStoreParameter',
    payload: { key: 'filterGeometry', value },
});

export const showWeatherMap = (date) => ({
    type: 'showWeatherMap',
    payload: date,
});
