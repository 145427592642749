import ReactSelect from 'react-select';
import styled from 'styled-components';

const StyledLabel = styled.label`
    display: block;
`;

const Select = ({
    options = [],
    label = '',
    value,
    onChange,
    className,
    style,
    ...props
}) => (
    <StyledLabel className={className} style={style}>
        {label}
        <ReactSelect
            options={options}
            value={value}
            onChange={onChange}
            styles={{
                menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 50,
                }),
            }}
            {...props}
        />
    </StyledLabel>
);

export default Select;
