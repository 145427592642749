import { useContext, useEffect, useState } from 'react';
import { getRenderPixel } from 'ol/render';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import { Style, Stroke } from 'ol/style';
import { vector } from '../Source';

const featureStyle = new Style({
    stroke: new Stroke({
        color: '#00000000',
        width: 1,
    }),
});

const MaskLayer = ({
    style = featureStyle,
    zIndex = 0,
    opacity = 0.2,
    feature,
}) => {
    const { map } = useContext(MapContext);
    const [olLayer, setOlLayer] = useState();
    const [source] = useState(vector({}));

    useEffect(() => {
        if (!map || !feature) return;

        const prerender = (event) => {
            const ctx = event.context;
            const mapSize = map.getSize();
            const tl = getRenderPixel(event, [0, 0]);
            const tr = getRenderPixel(event, [mapSize[0], 0]);
            const bl = getRenderPixel(event, [0, mapSize[1]]);
            const br = getRenderPixel(event, mapSize);

            const crd = feature.getGeometry().getCoordinates();
            const coords = crd[0][0];
            const pixels = coords
                .map((c) =>
                    getRenderPixel(event, map.getPixelFromCoordinate(c))
                )
                .reverse();
            const [first, ...rest] = pixels;

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(tl[0], tl[1]);
            ctx.lineTo(bl[0], bl[1]);
            ctx.lineTo(br[0], br[1]);
            ctx.lineTo(tr[0], tr[1]);
            ctx.closePath();

            ctx.moveTo(...first);
            rest.forEach((next) => {
                ctx.lineTo(...next);
            });
            ctx.closePath();

            const hexOpacity = Math.round(opacity * 255).toString(16);
            ctx.fillStyle = `#000000${hexOpacity}`;
            ctx.fill();
        };

        const postrender = (event) => {
            const ctx = event.context;
            ctx.restore();
        };

        const maskLayer = new OLVectorLayer({
            source,
            style,
        });

        maskLayer.on('prerender', prerender);
        maskLayer.on('postrender', postrender);

        map.addLayer(maskLayer);
        setOlLayer(maskLayer);

        return () => {
            if (map) {
                map.removeLayer(maskLayer);
            }
        };
    }, [map, feature]);

    useEffect(() => {
        if (!map || !olLayer) return;

        olLayer.setZIndex(zIndex);
    }, [map, zIndex, olLayer]);

    useEffect(() => {
        if (Array.isArray(feature)) {
            source.addFeatures(feature);
        } else if (feature) {
            source.addFeature(feature);
        }

        return () => {
            if (Array.isArray(feature)) {
                feature.forEach((f) => source.removeFeature(f));
            } else if (feature) {
                source.removeFeature(feature);
            }
        };
    }, [feature, source]);

    return null;
};

export default MaskLayer;
