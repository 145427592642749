import styled, { css } from 'styled-components';
import useStore, { actions } from '../../store/useStore';
import Table from '../Table';
import Modal from '../Modal';
import ImpactPopupContent from './ImpactPopupContent';
import { eventLabelToColor } from '../../services/events';
import { busyStates } from '../../store/constants';
import { isset } from '../../helpers/general';
import { uiPrimaryColor } from '../../style/colors';
import { parseLocationForTable, formatDateStr } from '../../helpers/data';

const Tooltip = styled.span`
    visibility: hidden;
    width: 120px;
    background-color: ${uiPrimaryColor};
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 140%;
    opacity: 0;
    transition: opacity 500ms ease 500ms;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent ${uiPrimaryColor} transparent transparent;
    }
`;

const TypeLegendContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: ${(props) => eventLabelToColor(props.type)};

    &:hover ${Tooltip} {
        visibility: visible;
        opacity: 1;
    }
`;

const TypeLegend = ({ type }) => {
    const label = type;
    return (
        <TypeLegendContainer type={type}>
            <Tooltip>{label}</Tooltip>
        </TypeLegendContainer>
    );
};

const columns = [
    // { label: 'loc id', accessor: (d) => d.location.eventlocationid },
    // {
    //     label: 'id',
    //     accessor: (d) => d.id,
    // },
    {
        label: 'type',
        accessor: (d) => <TypeLegend type={d.location.type} />,
    },
    {
        label: 'Datum',
        accessor: (d) =>
            d.startdatum ? formatDateStr(d.startdatum) : d.jaar_van || d.jaar,
        css: css`
            white-space: nowrap;
        `,
    },
    { label: 'Plaats', accessor: (d) => parseLocationForTable(d) },
    { label: 'Bron', accessor: (d) => d.bron },
    { label: 'Impact', accessor: (d) => d.impact },
    // { label: 'Referentie', accessor: (d) => <a href={d.bronUrl}>open</a> },
];

const DataViewer = () => {
    const { store, dispatch, fetchMoreImpacts } = useStore();
    const {
        selectedImpact,
        selectedEventLocation,
        selectedLocationImpacts,
        fetching,
    } = store;

    const modal = isset(selectedImpact.impactId) ? (
        <ImpactPopupContent
            impact={selectedImpact.data}
            impactId={selectedImpact.impactId}
            loading={selectedImpact.busyState !== busyStates.ready}
        />
    ) : null;

    const deselectImpact = () => {
        dispatch(actions.clearImpactSelection());
    };

    const locationSelected = selectedEventLocation.eventLocationId;
    let impacts = [];
    if (locationSelected) {
        if (selectedEventLocation.data) {
            impacts = selectedLocationImpacts;
        }
    } else {
        impacts = store.impacts;
    }

    const tableData = impacts.reduce((prev, impact, i) => {
        let locationIndex = 0;
        if (i > 0) {
            if (prev[i - 1].eventlocationid === impact.eventlocationid) {
                locationIndex = prev[i - 1].locationIndex;
            } else {
                locationIndex = prev[i - 1].locationIndex + 1;
            }
        }

        const row = {
            ...impact,
            locationIndex,
            onClick: () => dispatch(actions.selectImpact(impact)),
            onMouseEnter: isset(impact.eventlocationid)
                ? () => dispatch(actions.hoverImpactRow(impact.eventlocationid))
                : null,
            onMouseLeave: isset(impact.eventlocationid)
                ? () => dispatch(actions.hoverImpactRow(null))
                : null,
            css: `&& {
                    background: ${locationIndex % 2 ? '#00000020' : '#ffffff'};
            }`,
        };
        return [...prev, row];
    }, []);

    return (
        <div style={{ height: '100%' }}>
            <Table
                columns={columns}
                data={tableData}
                fetchMore={fetchMoreImpacts}
                busy={fetching}
            />
            <Modal visible={!!modal} close={deselectImpact}>
                {modal}
            </Modal>
        </div>
    );
};

export default DataViewer;
