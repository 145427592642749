import styled from 'styled-components';
import { format, addDays } from 'date-fns';
import { dateFromString } from '../../helpers/data';
import useStore, { actions } from '../../store/useStore';

const Container = styled.div`
    background: #ffffff;
    width: 120px;
    font-size: 0.8rem;
    padding: 0 2px;
`;

const Title = styled.h4`
    margin-top: 2px;
    margin-bottom: 6px;
    font-weight: 600;
`;

const DateNavigation = styled.div`
    display: inline-block;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    padding: 2px;
    background: #eee;
    border-left: 1px solid #333;
    border-right: 1px solid #333;
    cursor: pointer;
`;

const DateNavigationContainer = styled.div`
    border: 1px solid #333;
    margin: 4px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;

    & span {
        font-size: 0.7rem;
        margin: 0 4px;
    }

    & ${DateNavigation}:first-child {
        border-left: none;
    }

    & ${DateNavigation}:last-child {
        border-right: none;
    }
`;

const weatherMapRange = [
    dateFromString('2015-01-01'),
    dateFromString('2023-07-31'),
];

const LegendImg = styled.img.attrs({ alt: 'legenda' })`
    max-height: 480px;
`;

const WeatherMapControl = ({ legendUrl }) => {
    const { store, dispatch } = useStore();
    const { weathermapDate } = store;

    const dateNavigation = (direction) => () => {
        dispatch(
            actions.showWeatherMap((prev) => {
                if (
                    (direction < 0 && prev <= weatherMapRange[0]) ||
                    (direction > 0 && prev >= weatherMapRange[1])
                ) {
                    return prev;
                }
                return addDays(prev, direction);
            })
        );
    };

    const toggleMapserverMap = () => {
        dispatch(
            actions.setStoreParameter('useMapserverWeatherMap', (prev) => !prev)
        );
    };

    if (!weathermapDate) {
        return null;
    }

    return (
        <Container>
            <Title>Neerslag</Title>
            <DateNavigationContainer>
                <DateNavigation onClick={dateNavigation(-1)}>-</DateNavigation>
                <span>{format(weathermapDate, 'dd-MM-yyyy')}</span>
                <DateNavigation onClick={dateNavigation(+1)}>+</DateNavigation>
            </DateNavigationContainer>
            {/* <div style={{ fontSize: '0.75rem' }}>
                <input
                    type="checkbox"
                    checked={!store.useMapserverWeatherMap}
                    onChange={toggleMapserverMap}
                />
                knmi
            </div> */}

            {legendUrl && (
                <div>
                    <LegendImg src={legendUrl} />
                </div>
            )}
        </Container>
    );
};

export default WeatherMapControl;
