import {
    differenceInDays,
    parse as parseDate,
    eachDayOfInterval,
    addDays,
    format,
} from 'date-fns';

export const parseImpactLocation = (impact) => {
    if (!impact.location) {
        return null;
    }

    const { plaats, gemeente, provincie } = impact.location;
    let label;
    let value;
    if (plaats) {
        label = 'plaats';
        value = `${plaats} (${provincie})`;
    } else if (gemeente) {
        label = 'gemeente';
        value = `${gemeente} (${provincie})`;
    } else if (provincie) {
        label = 'provincie';
        value = provincie;
    } else {
        label = 'gebied';
        value = 'landelijk';
    }

    return { label, value };
};

export const parseLocationForTable = (impact) => {
    const parsed = parseImpactLocation(impact);
    if (!parsed) {
        return '-';
    }
    const { label, value } = parsed;
    if (label === 'provincie') return `${label} ${value}`;

    return value;
};

/**
 * Converts database format string (yyyy-mm-dd) to display format dd-mm-yyyy
 * @param {string} dateStr
 * @returns {string}
 */
export const formatDateStr = (dateStr) =>
    dateStr.split('-').reverse().join('-');

export const getDateString = (impactOrLocation) => {
    const { startdatum, einddatum, jaar_van, jaar_tot, jaar } =
        impactOrLocation;
    if (!startdatum) {
        if (!jaar_van) {
            return jaar || '';
        }
        if (!jaar_tot || jaar_van === jaar_tot) {
            return jaar_van;
        }
        return `${jaar_van}-${jaar_tot}`;
    }
    if (!einddatum || startdatum === einddatum) {
        return formatDateStr(startdatum);
    }
    return `${formatDateStr(startdatum)} t/m ${formatDateStr(einddatum)}`;
};

export const dateFromString = (dateStr) =>
    parseDate(dateStr, 'yyyy-MM-dd', new Date());

export const dateAsString = (date) => format(date, 'yyyy-MM-dd');

export const getDateRange = (impactOrLocation) => {
    const { startdatum, einddatum } = impactOrLocation;

    if (!startdatum) {
        return [];
    }
    const startDate = dateFromString(startdatum);
    if (!einddatum) {
        return [startDate];
    }
    let endDate = dateFromString(einddatum);

    if (differenceInDays(startDate, endDate) > 7) {
        endDate = addDays(startDate, 7);
    }
    return eachDayOfInterval({
        start: startDate,
        end: endDate,
    });
};
