import styled from 'styled-components';

const MainContainer = styled.div`
    display: ${(props) => (props.visible ? 'block' : 'none')};
    /* position: fixed; */
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
`;

const ContentContainer = styled.div`
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 96%;
    box-sizing: border-box;
    overflow: auto;
`;

const CloseButton = styled.span`
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 8px;

    &:hover,
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const Modal = ({ children, visible, close, ...props }) => {
    return (
        <MainContainer visible={visible}>
            <ContentContainer {...props}>
                <CloseButton onClick={close}>&times;</CloseButton>
                {children}
            </ContentContainer>
        </MainContainer>
    );
};

export default Modal;
