const assumeSingleValueArray = (arr, debugString = 'singleValueArray') => {
    if (arr.length > 1) {
        console.warn(`${debugString} ambigious`, arr);
    }
    if (arr.length < 1) {
        // console.warn(`${debugString} no data`, arr);
        return;
    }
    const [d] = arr;
    return d;
};

export const compileFeatureInfoResponse = (response) => {
    const pointData = assumeSingleValueArray(response, 'featureInfo');
    if (!pointData) return null;
    const value = assumeSingleValueArray(Object.values(pointData.data));
    if (isNaN(value)) return null;
    return value;
};
