import styled from 'styled-components';

const TooltipContainer = styled.div.attrs((props) => {
    const conditional = {};
    if (!props.show) {
        conditional.display = 'none';
    }
    if (props.$position) {
        conditional.left = props.$position.x;
        conditional.top = props.$position.y;
    }
    return {
        style: {
            top: 300,
            left: 300,
            ...conditional,
        },
    };
})`
    position: absolute;
    background: #fff;
    padding: 0.5em;
    transform: translate(-30%, calc(-100% - 10px));
    pointer-events: none;
    box-shadow: 0px 0px 10px #0000004d;
    border-radius: 4px;
    z-index: 2;
`;

const Tooltip = (props) => {
    return <TooltipContainer {...props} />;
};
export default Tooltip;
