import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { fetchMainEvents } from '../../services/events';
import useStore, { actions } from '../../store/useStore';
import ChipSelect from './ChipSelect';

const MainContainer = styled.div`
    margin-top: 32px;
`;

const MainEvents = () => {
    const { store, dispatch } = useStore();

    const [mainEvents, setMainEvents] = useState([]);

    useEffect(() => {
        const call = fetchMainEvents();

        call.then((data) => {
            setMainEvents(data);
        });

        return () => {
            call?.abort?.();
        };
    }, []);

    const handleEventClick = (optionValue) => {
        dispatch(
            actions.setFilterMainEvent(
                mainEvents.find((mainEvt) => mainEvt.id === optionValue)
            )
        );
    };

    return (
        <MainContainer>
            <ChipSelect
                options={mainEvents.map((e) => ({ ...e, value: e.id }))}
                values={
                    store.filters.mainEvent ? [store.filters.mainEvent.id] : []
                }
                toggleOption={handleEventClick}
            />
        </MainContainer>
    );
};

export default MainEvents;
