import { useState, useCallback, useRef, useEffect } from 'react';
import { fetchLocationImpacts } from './backend';

export const dataStates = {
    initializing: 0,
    ready: 1,
    fetching: 2,
    partially: 3,
    complete: 4,
    aborted: 5,
};

const useLocationImpactsData = () => {
    const [data, setData] = useState([]);
    const [locationId, setLocationId] = useState(null);

    // const status = useRef(dataStates.initializing);
    const status = useRef(dataStates.ready);

    const handleResults = useCallback((results) => {
        const filteredResults = results.reduce((prev, location) => {
            return [
                ...prev,
                ...location.impacts.map((impact) => ({
                    ...impact,
                    location,
                })),
            ];
        }, []);

        setData(filteredResults);
        status.current = dataStates.complete;
    }, []);

    const handleFetchError = useCallback((error) => {
        setData([]);
        status.current = dataStates.aborted;
        console.warn(error);
    }, []);

    const fetchMore = useCallback(() => {
        if (
            status.current !== dataStates.complete &&
            status.current !== dataStates.fetching &&
            status.current !== dataStates.initializing &&
            locationId
        ) {
            status.current = dataStates.fetching;
            return fetchLocationImpacts({ locationId })
                .then((results) => {
                    handleResults(results);
                })
                .catch((err) => {
                    handleFetchError(err);
                });
        }
    }, [handleResults, handleFetchError, locationId]);

    useEffect(() => {
        if (locationId) {
            status.current = dataStates.ready;
            fetchMore();
        } else {
            setData([]);
            status.current = dataStates.complete;
        }
    }, [locationId, fetchMore]);

    return {
        data,
        status,
        locationId,
        setLocationId,
        fetching: status.current === dataStates.fetching,
    };
};

export default useLocationImpactsData;
