export const busyStates = {
    ready: 0,
    initializing: 1,
    fetching: 2,
    submitting: 3,
    endReached: 4,
};

export const filterMainStates = {
    searchData: 0,
    climateEvents: 1,
};

export const filterMainStateLabels = {
    searchData: 'Data doorzoeken',
    climateEvents: 'Klimaatgebeurtenissen',
};

export const filterMainOptions = Object.entries(filterMainStates).map(
    ([key, value]) => ({ value, label: filterMainStateLabels[key] })
);
