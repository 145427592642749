import ImageWMS from 'ol/source/ImageWMS';

const defaultOptions = {
    params: {
        ratio: 1,
        serverType: 'geoserver',
    },
};

function imageWMS(options) {
    const { params, ...restOptions } = options;
    const mergedOptions = Object.assign(
        {},
        defaultOptions,
        { params: Object.assign({}, defaultOptions.params, options.params) },
        restOptions
    );
    return new ImageWMS(mergedOptions);
}

export default imageWMS;
