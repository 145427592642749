import { dummyFetch } from './dummy';
import { droogte } from '../style/colors';

/**
 * @typedef {('Hevige neerslag'|'Droogte'|'Overstroming')} TEventType
 */

/**
 * @type {Object.<TEventType, TEventType>}
 */
export const eventTypeConstants = {
    'Hevige neerslag': 'Hevige neerslag',
    Droogte: 'Droogte',
    Overstroming: 'Overstroming',
};

/**
 * @type {Object.<TEventType, string>}
 */
export const eventTypeLabels = {
    'Hevige neerslag': 'Hevige neerslag',
    Droogte: 'Droogte',
    Overstroming: 'Overstroming',
};

/**
 * @type {{value: TEventType, label: string}[]}
 */
export const eventTypes = Object.keys(eventTypeConstants).map((key) => {
    return { value: key, label: eventTypeLabels[key] };
});

export const eventTypeColors = {
    'Hevige neerslag': '#003f5c',
    Droogte: droogte,
    Overstroming: '#bc5090',
};

export const eventLabelToColor = (label) => {
    const eventType = eventTypes.find((et) => et.label === label);
    if (eventType) {
        return eventTypeColors[eventType.value];
    }
    return '#ccc';
};

export const mainEvents = [
    {
        label: 'Overstromingen Limburg 2021',
        id: 'Overstromingen Limburg 2021',
    },
    { label: 'Droogte 2022', id: 'Droogte 2022' },
];

export const fetchMainEvents = () => {
    return dummyFetch(mainEvents);
};
