import styled from 'styled-components';
import FilterOption from './FilterOption';

const MainContainer = styled.div`
    display: flex;
    gap: 8px;
`;

const ChipSelect = ({ options, values, toggleOption, ...props }) => {
    const getHandleClick = (opt) => () => {
        toggleOption(opt.value);
    };

    return (
        <MainContainer {...props}>
            {options.map((opt) => {
                return (
                    <FilterOption
                        key={opt.value}
                        label={opt.label}
                        selected={values?.includes(opt.value)}
                        onClick={getHandleClick(opt)}
                    />
                );
            })}
        </MainContainer>
    );
};

export default ChipSelect;
