import styled from 'styled-components';
import { PageTitle } from './Layout';

const Ref = styled.span`
    font-size: 80%;
`;

// Work in progress
const DroogteLandbouw = () => {
    return (
        <div>
            <PageTitle>Droogte in de landbouw - Introductie</PageTitle>
            <p>
                In de landbouw wordt gebruik gemaakt van zowel{' '}
                <i>grond- als oppervlaktewater</i>. Afhankelijk van de regio
                en/of sector wordt het watergebruik en watermanagement bepaald.
                Droogte ontstaat wanneer er meer water verdampt of verbruikt
                wordt dan er neerslag valt. Deze kunnen leiden tot{' '}
                <b>watertekorten</b>{' '}
                <Ref>(Klimaatadaptatie Nederland, 2023)</Ref>. We spreken dus
                van droogte wanneer er een lange of intense periode is die
                zodanig afwijkt van de normale situatie dat het{' '}
                <i>normale evenwicht</i> verstoord raakt <Ref>(IPLO, 2023)</Ref>
                . Door klimaatverandering zullen periodes van droogte langer en
                vaker voorkomen en zal daarnaast meer verdamping plaatsvinden{' '}
                <Ref>(IPLO, 2023)</Ref>.
            </p>
            <p>
                Het effect van droogte wordt in kaart gebracht middels{' '}
                <i>impacts</i>. Een impact is de uitwerking van
                klimaat-gerelateerde risico's (zoals weersextremen) waarbij er
                een <b>waarneembaar effect</b> is voor: mens; welzijn;
                gezondheid; ecosystemen en soorten; economisch, sociaal en
                cultureel goed; diensten (inclusief ecosysteem diensten); of
                infrastructuur <Ref>(IPCC, 2018; 2022)</Ref>. Daar komt bij dat
                de mate van impact niet alleen afhangt van het weer, maar ook
                van andere factoren zoals de mate waarin mensen aan droogte zijn
                blootgesteld en in hoeverre ze er vatbaar of kwetsbaar voor zijn{' '}
                <Ref>(IPCC, 2014)</Ref>. Neem bijvoorbeeld de landbouw waarbij
                door klimaatverandering de kans op droogte impacts toeneemt.
                Daarnaast wordt de mate van impact bepaald door hoe{' '}
                <i>vatbaar</i> de boer is afhankelijk van hoe droogte gevoelig
                het gebied is waar landbouw plaatsvindt. En hoe <i>kwetsbaar</i>{' '}
                de boer is afhankelijk van de droogtegevoeligheid van het gewas
                of de financiële bescherming tegen gewasschade als gevolg van
                droogte. Als laatste hebben we het over{' '}
                <b>directe en indirecte impacts</b>, waarbij directe impacts de{' '}
                <i>directe fysieke schade</i>
                zijn van de droogte en indirecte impacts de{' '}
                <i>consequenties van de fysieke schade</i>{' '}
                <Ref>(Ding et al., 2011)</Ref>.
            </p>
            <p>... nog doen: rest tekst toevoegen</p>
        </div>
    );
};
// @todo zie mail Chloe 6-12-2023
export default DroogteLandbouw;
