import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';
import { paths } from '../../App';
// import mainImg from '../../assets/landingspagina.png';
import bgrImg from '../../assets/background.png';
import titleImg from '../../assets/kim-title.png'; //1620x259

const MainContainer = styled.div`
    background: url(${bgrImg});
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 100vh;
    overflow: auto;
`;

const backgroundColor = '#ffffffaa';
const hoverColor = '#ffffffdd';
const ContentBlock = styled.div`
    background: ${backgroundColor};
    padding: 16px 32px;
    color: #333;
    border-radius: 8px;
    box-sizing: border-box;
`;

const TitleWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 100px;
`;
const TitleImg = styled.img.attrs({ src: titleImg })`
    max-width: 90%;
`;
const Title = (props) => (
    <TitleWrapper {...props}>
        <TitleImg />
    </TitleWrapper>
);

const ColumnWrapper = styled.div`
    margin-top: 48px;
    display: flex;
    padding-left: 2%;
    padding-right: 2%;
    gap: 2%;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-direction: column;
        padding-bottom: 32px;
        gap: 32px;
    }
`;
const Column = styled.div`
    flex: 1 1 0;
    min-width: 0;
    max-width: 600px;
`;

const MainContentBlock = styled(ContentBlock)`
    line-height: 1.5;
    box-sizing: border-box;
    height: 100%;

    & p {
        margin-bottom: 2em;
    }
`;

const Link = styled(RRLink)`
    text-decoration: none;
`;

const LinksContainer = styled.div`
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 32px;
`;

const navWidth = 600;
const navHeight = 200;

const triangleW = navHeight / 2;
const netW = navWidth - triangleW;
const gap = 32;

const NavButton = styled(Link)`
    background: ${backgroundColor};
    color: #333;
    padding: 0;
    padding-left: ${gap}px;
    position: relative;
    height: ${navHeight}px;
    width: ${netW}px;
    max-width: calc(100% - ${2 * gap}px - ${triangleW}px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border-radius: 8px 0 0 8px;
    gap: 16px;

    & > * {
        /* flex: 1 1;
        /* min-height: 0px; */
        display: block;
        margin: 0;
    }

    & p {
        overflow: auto;
    }

    &::after {
        content: '';
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 ${triangleW}px ${triangleW}px ${triangleW}px;
        border-color: transparent transparent ${backgroundColor} transparent;
        transform: translateX(-50%) rotate(90deg);
        transform-origin: center bottom;
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
    }

    &:hover {
        background-color: ${hoverColor};
        cursor: pointer;
    }
    &:hover::after {
        border-color: transparent transparent ${hoverColor} transparent;
    }
`;

const Home = () => {
    return (
        <MainContainer>
            <Title />
            <ColumnWrapper>
                <Column>
                    <MainContentBlock>
                        <p>
                            Klimaatverandering is niet meer alleen iets van de
                            verre toekomst. Steeds vaker ondervinden we nu al de
                            gevolgen van extreme weersgebeurtenissen, zoals
                            wateroverlast, perioden van droogte en extreme
                            hitte, en natuurbranden. Hoewel deze gebeurtenissen
                            niet altijd direct zijn toe te schrijven aan
                            klimaatverandering is inmiddels wel duidelijk dat
                            dit soort extremen steeds vaker voorkomen en dat we
                            onze steden, landbouw en natuur moeten gaan
                            inrichten om de gevolgen van deze weersextremen op
                            te vangen.
                        </p>
                        <p>
                            Met de Klimaat Impact Monitor willen wij gegevens
                            over de impacts van actuele extreme events
                            (bijvoorbeeld schade aan gebouwen, verminderde
                            gewasopbrengst, ziektemeldingen en oversterfte) die
                            mogelijk te relateren zijn aan klimaatverandering in
                            één omgeving verzamelen, ontsluiten en visualiseren.
                        </p>
                    </MainContentBlock>
                </Column>
                <Column>
                    <LinksContainer>
                        <NavButton to={paths.specials}>
                            <h3>Gevolgen van droogte</h3>
                            <p>
                                In verdiepende overzichten (storylines) wordt
                                nader ingegaan op de impact van droogte op
                                verschillende sectoren door de jaren heen
                            </p>
                        </NavButton>
                        <NavButton to={paths.viewer}>
                            <h3>Zoeken in de database</h3>
                            <p>
                                Bekijk krantenartikelen en andere informatie
                                over gebeurtenissen die plaats hebben gevonden
                                als gevolg van extreme weersomstandigheden zoals
                                droogte en hevige neerslag
                            </p>
                        </NavButton>
                    </LinksContainer>
                </Column>
            </ColumnWrapper>
        </MainContainer>
    );
};

export default Home;
