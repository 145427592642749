import { busyStates, filterMainStates } from './constants';

/**
 * @typedef {Object} TOption
 * @property {string} label
 * @property {*} value
 */
/** @typedef {import('../services/backend').TLocation} TLocation */
/** @typedef {import('../services/backend').TImpact} TImpact */
/**
 * @typedef  {Object} TEventLocationSelection
 * @property {TLocation} eventLocation
 * @property {*} feature - OpenLayers geoJSON feature
 * @property {*} properties
 */

/**
 * @typedef {Object} TStore
 * @property {Object} config
 * @property {Object} config.dateRange
 * @property {Date} config.dateRange.from
 * @property {Date} config.dateRange.to
 * @property {string} busyState - one of the constants busyStates
 *
 * @property {*} centroids - climate event data
 *
 * @property {Object} filters
 * @property {number} filters.main - one of the constants filterMainStates
 * @property {Object} filters.mainEvent - the selected main event
 * @property {number | string} filters.mainEvent.id
 * @property {string} filters.mainEvent.label
 * @property {Object} filters.location
 * @property {TOption} filters.location.provence
 * @property {TOption} filters.location.municipality
 * @property {array} filters.eventTypes - array of values of event types
 * @property {Object} filters.date
 *
 * @property {Object} selectedEventLocation - selected location (on map)
 * @property {TEventLocationSelection} selectedEventLocation.data - impact location data
 * @property {number} selectedEventLocation.eventLocationId
 * @property {string} selectedEventLocation.busyState - one of the constants busyStates
 *
 * @property {Object} selectedImpact - selected climate event data (table row)
 * @property {TImpact} selectedImpact.data - climate event data
 * @property {number} selectedImpact.impactId
 * @property {string} selectedImpact.busyState - one of the constants busyStates
 *
 * @property {Date} weathermapDate - for this date the rainfall map is shown (null for none)
 * @property {boolean} useMapserverWeatherMap - temp setting for comparing mapServer weather with knmi wms
 *
 * @property {number} hoveredImpact - eventLocationId of the hovered impact (in table) @ todo change to hovered location
 *
 * @property {Object} filterGeometry - feature of a filter location, ie municipality. We'll zoom the map to it's extent when it changes
 *
 */

/**
 * @type {TStore}
 */
const initialDateRange = {
    from: new Date(1900, 0, 1),
    to: new Date(),
};
const initialState = {
    config: {
        dateRange: initialDateRange,
    },
    busyState: busyStates.ready,
    centroids: null,
    filters: {
        main: filterMainStates.searchData,
        mainEvent: null,
        location: {
            provence: null,
            municipality: null,
        },
        eventTypes: [],
        date: { ...initialDateRange },
    },
    selectedEventLocation: {
        data: null,
        eventLocationId: null,
        busyState: busyStates.initializing,
    },
    selectedImpact: {
        data: null,
        impactId: null,
        busyState: busyStates.initializing,
    },
    weathermapDate: null,
    useMapserverWeatherMap: false,
    hoveredImpact: null,
    filterGeometry: null,
};

export default initialState;
