import { isset } from '../helpers/general.js';
import { busyStates } from './constants.js';
import featureFilter from './featureFilter.js';
import { actions } from './useStore.js';
import { dateFromString } from '../helpers/data.js';
import { isSameDay } from 'date-fns';

/**
 * @typedef {import('./initialState.js').TStore} TStore
 */

const updateValue = (newValue, currentValue, currentState) => {
    if (typeof newValue === 'function') {
        return newValue(currentValue, currentState);
    }
    return newValue;
};
/**
 *
 * @param {TStore} state
 * @returns
 */
const checkSelection = (state) => {
    if (isset(state.selectedEventLocation.eventLocationId)) {
        let visible = true;
        if (state.selectedEventLocation.data?.feature) {
            visible = featureFilter({
                feature: state.selectedEventLocation.data.feature,
                mainFilter: state.filters.main,
                mainEvent: state.filters.mainEvent,
                locationFilters: state.filters.location,
                eventFilters: state.filters.eventTypes,
                geojson: true,
            });
        }
        if (!visible) {
            return {
                ...state,
                selectedEventLocation: {
                    data: null,
                    eventLocationId: null,
                    busyState: busyStates.ready,
                },
                weathermapDate: null,
            };
        }
    }
    return state;
};

/**
 *
 * @param {TStore} state
 * @param {{type: string, payload: any}} action
 * @returns {TStore}
 */
const reducer = (state, action) => {
    switch (action.type) {
        case 'setBusyState': {
            const newBusyState = action.payload;
            return {
                ...state,
                busyState: updateValue(newBusyState, state.busyState, state),
            };
        }
        case 'setStoreParameter': {
            const { key, value } = action.payload;
            return {
                ...state,
                [key]: updateValue(value, state[key], state),
            };
        }
        case 'setMainFilterState': {
            const value = action.payload;
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    main: updateValue(value, state.filters.main, state),
                },
            };
            return checkSelection(newState);
        }
        case 'setFilterMainEvent': {
            const value = action.payload;
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    mainEvent: updateValue(
                        value,
                        state.filters.mainEvent,
                        state
                    ),
                },
            };
            return checkSelection(newState);
        }
        case 'setFilterLocation': {
            const { put = false, ...newLocation } = action.payload;
            const base = put
                ? state.filters.location
                : { provence: null, municipality: null, city: null };
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    location: { ...base, ...newLocation },
                },
            };
            return checkSelection(newState);
        }
        case 'setFilterEventTypes': {
            const values = action.payload;
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    eventTypes: values || [],
                },
            };
            return checkSelection(newState);
        }
        case 'toggleFilterEventType': {
            const value = action.payload;
            let updatedEvents;
            if (state.filters.eventTypes.includes(value)) {
                updatedEvents = state.filters.eventTypes.filter(
                    (evt) => evt !== value
                );
            } else {
                updatedEvents = [...state.filters.eventTypes, value];
            }
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    eventTypes: updatedEvents,
                },
            };
            return checkSelection(newState);
        }
        case 'setFilterDateFrom': {
            const newDate = action.payload;
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    date: { ...state.filters.date, from: newDate },
                },
            };
            return checkSelection(newState);
        }
        case 'setFilterDateTo': {
            const newDate = action.payload;
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    date: { ...state.filters.date, to: newDate },
                },
            };
            return checkSelection(newState);
        }
        case 'selectEventLocation': {
            const { eventLocationId, data = {} } = action.payload;
            const { properties, feature } = data;
            const startDate = properties?.startdate
                ? dateFromString(properties.startdate)
                : null;
            let newState = {
                ...state,
                selectedEventLocation: {
                    data: { properties, feature },
                    eventLocationId,
                    busyState: busyStates.initializing,
                },
                selectedImpact: {
                    data: null,
                    impactId: null,
                    busyState: busyStates.initializing,
                },
                weathermapDate: startDate,
            };
            return checkSelection(newState);
        }
        case 'selectEventLocationById': {
            const eventLocationId = action.payload;
            let newState = {
                ...state,
                selectedEventLocation: {
                    data: null,
                    eventLocationId,
                    busyState: busyStates.initializing,
                },
                selectedImpact: {
                    data: null,
                    impactId: null,
                    busyState: busyStates.initializing,
                },
            };
            // if (
            //     state.selectedImpact &&
            //     state.selectedImpact.eventLocationId !== eventLocationId
            // ) {
            //     newState = {
            //         ...newState,
            //         selectedImpact: {
            //             data: null,
            //             eventLocationId,
            //             busyState: busyStates.initializing,
            //         },
            //     };
            // }
            return checkSelection(newState);
        }
        case 'selectImpact': {
            const impact = action.payload;

            const newState = {
                ...state,
                selectedImpact: {
                    data: impact,
                    impactId: impact.id,
                    busyState: busyStates.ready,
                },
            };

            const startDate = impact?.startdatum
                ? dateFromString(impact.startdatum)
                : null;

            if (!isSameDay(startDate, state.weathermapDate)) {
                newState.weathermapDate = startDate;
            }
            return newState;
        }
        case 'clearImpactSelection': {
            const newState = {
                ...state,
                selectedImpact: {
                    data: null,
                    impactId: null,
                    busyState: busyStates.ready,
                },
            };
            if (!state.selectedEventLocation?.eventLocationId) {
                newState.weathermapDate = null;
            }
            return newState;
        }
        case 'clearEventLocationsSelection': {
            const newState = {
                ...state,
                selectedEventLocation: {
                    data: null,
                    eventLocationId: null,
                    busyState: busyStates.ready,
                },
                weathermapDate: null,
            };
            return checkSelection(newState);
        }
        case 'startFetchEventLocationsSelection': {
            const eventLocationId = action.payload;
            return {
                ...state,
                selectedEventLocation: {
                    data: null,
                    eventLocationId,
                    busyState: busyStates.fetching,
                },
            };
        }
        case 'endFetchEventLocationsSelection': {
            const { eventlocationid, data } = action.payload;
            if (
                eventlocationid !== state.selectedEventLocation.eventLocationId
            ) {
                return state;
            }
            return {
                ...state,
                selectedEventLocation: {
                    data,
                    eventLocationId: eventlocationid,
                    busyState: busyStates.ready,
                },
            };
        }
        case 'featureClicked': {
            let newState = state;
            const clickData = action.payload;

            const { feature, properties } = clickData;

            if (!properties) {
                return reducer(state, actions.clearEventLocationsSelection());
            }

            const { eventlocationid } = properties;

            newState = reducer(
                newState,
                actions.selectEventLocation(eventlocationid, {
                    properties,
                    feature,
                })
            );
            return newState;
        }
        case 'hoverImpactRow': {
            const eventlocationid = action.payload;
            return {
                ...state,
                hoveredImpact: eventlocationid,
            };
        }
        case 'showWeatherMap': {
            const date = action.payload;
            return {
                ...state,
                weathermapDate: updateValue(date, state.weathermapDate, state),
            };
        }

        default:
            throw new Error(`Unknown action ${action.type}`);
    }
};

export default reducer;
