import React, { useRef, useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import MapContext from './MapContext';
import * as ol from 'ol';

const MapContainer = styled.div`
    min-width: 300px;
    min-height: 200px;
    height: 100%;
    width: 100%;

    & .ol-control {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        padding: 2px;
    }
    & .ol-full-screen {
        top: 8px;
        right: 8px;
    }

    & .ol-scale-line {
        background: rgba(255, 255, 255, 0.4);
        position: absolute;
        bottom: 8px;
        right: 8px;
        border-radius: 4px;
        padding: 2px;
    }

    & .ol-scale-line-inner {
        border: 1px solid #000;
        border-top: none;
        font-size: 10px;
        text-align: center;
        margin: 1px;
        will-change: contents, width;
        transition: all 0.25s;
    }
`;

const Map = forwardRef(
    (
        {
            children,
            zoom,
            center,
            dimensions,
            projection = 'EPSG:3857',
            minZoom,
            maxZoom,
            className,
        },
        ref
    ) => {
        const localRef = useRef();
        const mapRef = ref || localRef;

        const [map, setMap] = useState(null);

        // on component mount
        useEffect(() => {
            let options = {
                view: new ol.View({ projection, minZoom, maxZoom }),
                layers: [],
                controls: [],
                overlays: [],
            };

            let mapObject = new ol.Map(options);
            mapObject.setTarget(mapRef.current);
            setMap(mapObject);

            return () => mapObject.setTarget(undefined);
        }, [projection, maxZoom, minZoom, mapRef]);

        // zoom change handler
        useEffect(() => {
            if (!map) return;

            map.getView().setZoom(zoom);
        }, [zoom, map]);

        // center change handler
        useEffect(() => {
            if (!map) return;

            map.getView().setCenter(center);
        }, [center, map]);

        // resize handler
        useEffect(() => {
            if (!map) return;

            map.updateSize();
        }, [dimensions, map]);

        return (
            <MapContext.Provider value={{ map }}>
                <MapContainer ref={mapRef} className={className}>
                    {children}
                </MapContainer>
            </MapContext.Provider>
        );
    }
);

export default Map;
