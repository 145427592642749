import { getWMTS, tileWMS } from '../components/OpenLayers/Source';

const popParamCaseInsensitive = (
    searchParams,
    key,
    defaultValue,
    pop = true
) => {
    const searchParamKey = [...searchParams.keys()].find(
        (k) => k.toLowerCase() === key.toLowerCase()
    );
    if (searchParamKey) {
        const value = searchParams.get(searchParamKey);
        if (pop) {
            searchParams.delete(searchParamKey);
        }
        return value;
    }
    return defaultValue;
};

/**
 *
 * @param {string} url
 * @param {Object} defaultValues
 * @param {string} defaultValues.layers
 * @param {string} defaultValues.crs
 * @returns
 */
export const parseResourceUrl = (url, defaultValues) => {
    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;
    const layers = popParamCaseInsensitive(
        searchParams,
        'layers',
        defaultValues.layers
    );
    const serverType = popParamCaseInsensitive(
        searchParams,
        'serverKey',
        url.includes('mapserver') ? 'mapserver' : 'geoserver'
    );
    const query_layers = popParamCaseInsensitive(
        searchParams,
        'query_layers',
        layers
    );
    const crs = popParamCaseInsensitive(
        searchParams,
        'crs',
        defaultValues.crs || 'EPSG:3857'
    );
    const infoFormat = popParamCaseInsensitive(
        searchParams,
        'INFO_FORMAT',
        'application/json'
    );

    const options = {
        url: `${urlObj.origin}${urlObj.pathname}?${searchParams.toString()}`,
        params: {
            layers,
            serverType,
            query_layers,
            CRS: crs,
            INFO_FORMAT: infoFormat,
        },
    };
    return options;
};

/**
 * @typedef {Object} TLayerConfig
 * @property {string} name
 * @property {string} onlineresource
 * @property {('wmts'|'wms')} protocol
 * @property {string} featureinfourl - temporary ignored
 * @property {string} info - temporary ignored
 *
 * @typedef {Object} TInitializedLayer
 * @extends TLayerConfig
 * @property {Object} source - The OpenLayers (Tile) source object
 * @property {Object} infoSource - The OpenLayers (wms) source object to use for getFeatureInfo calls
 *
 * @param {TLayerConfig[]} layers
 * @returns {TInitializedLayer[]}
 */
export const createLayerSources = (layers) => {
    return Promise.all(
        layers.map(async (layer) => {
            let source;
            let infoSource;
            if (layer.protocol === 'wmts') {
                source = await getWMTS(layer.onlineresource, layer.name).catch(
                    (error) => {
                        console.error(error);
                    }
                );
                // if (layer.featureinfourl) {
                //     const layerProjectionCode = source?.projection?.getCode();
                //     infoSource = tileWMS(
                //         parseResourceUrl(layer.featureinfourl, {
                //             layers: layer.name,
                //             crs: layerProjectionCode,
                //         })
                //     );
                // }
            } else {
                let options = parseResourceUrl(layer.onlineresource, {
                    layers: layer.name,
                });
                if (layer.options) {
                    options = { ...options, ...layer.options };
                }
                source = tileWMS(options);
            }
            const result = {
                ...layer,
                source,
                // infoSource,
                // info: (
                //     <div
                //         dangerouslySetInnerHTML={{
                //             __html: layer.info,
                //         }}
                //     />
                // ),
            };
            return result;
        })
    );
};

/**
 *
 * @typedef {Object} TLegendEntry
 * @property {string} color - hex value
 * @property {string} label
 * @property {string} quantity - integer as string
 *
 * @param {{source: object, name: string}} layer
 * @param {boolean} json
 * @returns  {Promise<Object.<string, TLegendEntry>>}
 */
export const getLegendData = (layer, json = false) => {
    if (json) {
        return new Promise((resolve, reject) => {
            const legendUrl = layer.source.getLegendUrl(undefined, {
                layer: layer.name,
                sld_version: '1.1.0',
                format: 'application/json',
            });
            if (!legendUrl) {
                reject(new Error('Legend not available for this layer'));
                return;
            }
            fetch(legendUrl)
                .then((response) => response.json())
                .then((result) => {
                    const entries =
                        result?.Legend?.[0]?.rules?.[0]?.symbolizers?.[0]
                            ?.Raster?.colormap?.entries;
                    resolve({
                        json: entries.reduce((prev, cur, i) => {
                            return { ...prev, [cur.quantity]: cur };
                        }, {}),
                    });
                })
                .catch(reject);
        });
    }

    return new Promise((resolve, reject) => {
        const legendUrl = layer.source.getLegendUrl(undefined, {
            layer: layer.name,
            sld_version: '1.1.0',
        });
        if (!legendUrl) {
            reject(new Error('Legend not available for this layer'));
            return;
        }
        resolve({ imgUrl: legendUrl });
    });
};

/**
 *
 * @param {{source: object, name: string}} layer
 * @returns  {string}
 */
export const getLegendUrl = (layer) => {
    if (layer.legendUrl) {
        return layer.legendUrl;
    }
    const legendUrl = layer.source.getLegendUrl(undefined, {
        layer: layer.name,
        sld_version: '1.1.0',
    });
    if (!legendUrl) {
        throw new Error('Legend not available for this layer');
        return null;
    }
    return legendUrl;
};
