import styled from 'styled-components';

export const CustomControlsWrapper = styled.div`
    z-index: 1;
    padding: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    overflow: hidden;

    & > * {
        pointer-events: auto;
    }
`;
