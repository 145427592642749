import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import useDimensions from '../../helpers/useDimensions';
import Progress from '../Progress';
import { isset } from '../../helpers/general';

const SizeWrapper = styled.div`
    overflow: hidden;
    height: 100%;
`;

const TableContainer = styled.div`
    overflow-y: auto;
    height: ${(props) => props.height}px;
    /* height: 500px; */
`;

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const clickableRowStyle = css`
    cursor: pointer;

    &:hover {
        background: #00000040;
    }
`;

const Row = styled.tr`
    &:nth-child(even) {
        background: #00000020;
    }

    ${(props) => props.css}

    ${(props) => props.onClick && clickableRowStyle}
`;

const Cell = styled.td`
    padding: 0 8px;
    ${(props) => props.css}
`;

const HeaderCell = styled.th`
    background: #808080;
    color: #ffffff;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 2;
`;

const Table = ({ columns, data, fetchMore, busy, ...passprops }) => {
    const wrapper = useRef();
    const [, parentHeight] = useDimensions(wrapper);
    const scrollContainer = useRef();
    const fetchMoreRef = useRef(fetchMore);

    useEffect(() => {
        fetchMoreRef.current = fetchMore;
    }, [fetchMore]);

    useEffect(() => {
        // const {scrollHeight, offsetHeight, scrollTop} = scrollDimensions || {};
        const { scrollHeight, offsetHeight, scrollTop } =
            scrollContainer.current || {};
        if (!(isset(scrollHeight) && isset(offsetHeight) && isset(scrollTop))) {
            return;
        }
        if (data.length < 10 || scrollHeight - offsetHeight - scrollTop < 10) {
            fetchMoreRef.current?.();
        }
    }, [
        data.length,
        scrollContainer.current?.scrollHeight,
        scrollContainer.current?.offsetHeight,
        scrollContainer.current?.scrollTop,
    ]);

    const handleScroll = (e) => {
        const { target } = e;
        if (
            target.scrollHeight - target.offsetHeight - target.scrollTop < 10 &&
            fetchMore
        ) {
            fetchMore();
        }
    };

    return (
        <SizeWrapper ref={wrapper}>
            <TableContainer
                ref={scrollContainer}
                onScroll={handleScroll}
                height={parentHeight}
                {...passprops}
            >
                <StyledTable>
                    <thead>
                        <tr>
                            {columns.map((col, c) => {
                                return (
                                    <HeaderCell key={col.key || c}>
                                        {col.label}
                                    </HeaderCell>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, r) => {
                            return (
                                <Row
                                    key={r}
                                    onClick={row.onClick}
                                    onMouseEnter={row.onMouseEnter}
                                    onMouseLeave={row.onMouseLeave}
                                    style={row.style}
                                    css={row.css}
                                >
                                    {columns.map((col, c) => {
                                        const getData =
                                            col.accessor || ((d) => d[col.id]);
                                        const cellContent = getData(row);
                                        return (
                                            <Cell
                                                key={`${r}-${c}`}
                                                css={col.css}
                                            >
                                                {cellContent}
                                            </Cell>
                                        );
                                    })}
                                </Row>
                            );
                        })}
                    </tbody>
                </StyledTable>
                {busy && <Progress />}
            </TableContainer>
        </SizeWrapper>
    );
};
Table.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            accessor: PropTypes.func,
        })
    ),
    data: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func,
            onMouseEnter: PropTypes.func,
            onMouseLeave: PropTypes.func,
            // ... data values
        })
    ),
};

export default Table;
