import municipalitiesData from './municipalities.json';
import { CancelablePromise } from './backend';
import WKT from 'ol/format/WKT';

const wktFormatter = new WKT();

export const provences = [
    { value: 'GR', label: 'Groningen', provinciecodePV: 'PV20' },
    { value: 'FR', label: 'Friesland', provinciecodePV: 'PV21' },
    { value: 'DR', label: 'Drenthe', provinciecodePV: 'PV22' },
    { value: 'OV', label: 'Overijssel', provinciecodePV: 'PV23' },
    { value: 'FL', label: 'Flevoland', provinciecodePV: 'PV24' },
    { value: 'GD', label: 'Gelderland', provinciecodePV: 'PV25' },
    { value: 'UT', label: 'Utrecht', provinciecodePV: 'PV26' },
    { value: 'NH', label: 'Noord-Holland', provinciecodePV: 'PV27' },
    { value: 'ZH', label: 'Zuid-Holland', provinciecodePV: 'PV28' },
    { value: 'ZL', label: 'Zeeland', provinciecodePV: 'PV29' },
    { value: 'NB', label: 'Noord-Brabant', provinciecodePV: 'PV30' },
    { value: 'LB', label: 'Limburg', provinciecodePV: 'PV31' },
].sort((a, b) => a.label.localeCompare(b.label));

export const municipalities = municipalitiesData
    .map((m) => {
        const {
            Gemeentecode,
            GemeentecodeGM,
            Gemeentenaam,
            Provinciecode,
            ProvinciecodePV,
            Provincienaam,
        } = m;
        const provence = provences.find(
            (p) => p.provinciecodePV === ProvinciecodePV
        );
        return {
            value: GemeentecodeGM,
            label: Gemeentenaam,
            provence: provence.value,
        };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

const locationServiceUrl =
    'https://api.pdok.nl/bzk/locatieserver/search/v3_1/free';

/**
 *
 * @param {Object} locationParams
 * @param {string} locationParams.municipalityCode
 * @param {string} locationParams.provence
 * @returns
 */
export const getLocationGeometry = (locationParams) => {
    const controller = new AbortController();
    const signal = controller.signal;
    return new CancelablePromise((resolve, reject) => {
        let searchQ = '';
        if (locationParams.municipalityCode) {
            searchQ = `${locationParams.municipalityCode.replace(
                'GM',
                ''
            )} and type:gemeente`;
        } else if (locationParams.provence) {
            searchQ = `${locationParams.provence} and type:provincie`;
        } else {
            throw new Error('No location parameter found');
        }
        const query = new URLSearchParams({
            q: searchQ,
            fl: 'id,geometrie_ll,provinciecode',
            fq: '*:*',
        });
        const url = `${locationServiceUrl}?${query}`;

        const options = { signal };

        fetch(url, options)
            .then((response) => {
                // console.group();
                // console.log('getLocationGeometry', locationParams);
                // console.log('response:', response);
                // console.groupEnd();
                // (response.status >= 200 && response.status < 300)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Fetch error');
            })
            .then((responseData) => {
                const { response } = responseData;
                if (response.numFound > 0) {
                    if (response.numFound > 1) {
                        console.group();
                        console.warn(
                            'Found multiple locations!',
                            locationParams
                        );
                        console.log('locationParams:', locationParams);
                        console.log('response:', response);
                        console.groupEnd();
                    }
                    const wkt = response.docs[0].geometrie_ll;
                    const feature = wktFormatter.readFeature(wkt, {
                        dataProjection: 'EPSG:4326',
                        featureProjection: 'EPSG:3857',
                    });
                    resolve(feature);
                } else {
                    reject(new Error('Location not found'));
                }
            })
            .catch((err) => {
                if (err instanceof DOMException && err.name === 'AbortError') {
                    // reject(null);
                    return;
                }
                reject(err);
            });
    }, controller);
};

/* location service PDOK examples:
https://geodata.nationaalgeoregister.nl/locatieserver/v3/free?q=Utrecht%20and%20type:provincie&fl=id,geometrie_ll,provinciecode&fq=*:*
https://geodata.nationaalgeoregister.nl/locatieserver/v3/free?q=Utrecht%20and%20type:provincie&fl=id,geometrie_ll,provinciecode&fq=*:*

NB fq=*:* needed to find provences
fl=... determines which fields are returned. 
    geometrie_ll for geometry

{
"response": {
"numFound": 1,
"start": 0,
"maxScore": 39.992348,
"docs": [
{
    "geometrie_ll": "MULTIPOLYGON(((5.05181 51.85748,5.05381 51.85739,5.05919 51.85815,5.06236, .... ,5.05181 51.85748)))",
        "provinciecode": "PV26",
    "id": "pro-3c35250f0081bd7bc08056e290fbceb4"
    }
    ]
    }
    }

Gebruik veldnamen (contextgevoelig zoeken). D.m.v. het toevoegen van veldnamen voor de daadwerkelijke zoektermen kan het default zoekveld worden aangepast. Bijv. bij q=Utrecht wordt gezocht in het default zoekveld (tekst bij de vrije geocoder), maar bij q=woonplaatsnaam:Utrecht wordt gezocht in het veld woonplaatsnaam.

*/
