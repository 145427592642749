import { eventTypes } from '../services/events';
import { filterMainStates } from './constants';

const sameStrings = (a, b) => {
    return (
        a?.localeCompare(b, 'nl', {
            sensitivity: 'base',
        }) === 0
    );
};

const featureFilter = ({
    feature,
    mainFilter,
    mainEvent,
    locationFilters,
    eventFilters,
    dateFilterFrom,
    dateFilterTo,
    geojson = false,
}) => {
    let show = true;

    const getProperty = (key) => {
        return geojson ? feature.get(key) : feature.properties?.[key];
    };

    if (mainFilter === filterMainStates.searchData) {
        const provenceCode = getProperty('provinciecode');
        show =
            !locationFilters.provence ||
            sameStrings(provenceCode, locationFilters.provence.value);
        if (!show) return show;

        const municipality = getProperty('municipality');
        show =
            !locationFilters.municipality ||
            sameStrings(municipality, locationFilters.municipality.label);

        if (!show) return show;

        const eventType = getProperty('climate_impact_event');
        let eventTypeValue = null;
        if (eventType) {
            const eventTypeMatch = eventTypes.find(
                (e) => e.label === eventType
            );
            if (eventTypeMatch) {
                eventTypeValue = eventTypeMatch.value;
            } else {
                console.warn(`Unknown eventType ${eventType}`);
            }
        }
        show = eventTypeValue !== null && eventFilters.includes(eventTypeValue);
        if (!show) return show;

        const startDate = getProperty('startdate');
        const endDate = getProperty('enddate');
        if (startDate) {
            const startDateObject = new Date(startDate);
            if (startDateObject) {
                if (startDateObject > dateFilterTo) {
                    return false;
                }
            }
        }
        if (endDate) {
            const endDateObject = new Date(endDate);
            if (endDateObject) {
                if (endDateObject < dateFilterFrom) {
                    return false;
                }
            }
        }
    } else if (mainFilter === filterMainStates.climateEvents && mainEvent?.id) {
        const groep = getProperty('groep');
        if (!sameStrings(groep, mainEvent.id)) {
            return false;
        }
    }

    return show;
};

export default featureFilter;
