import { Routes, Route } from 'react-router-dom';
import { StoreProvider } from './store/useStore';
import Home from './components/Home';
import MainView from './components/MainView';
import Specials from './components/Specials';
// import MainLayout from './components/MainLayout';
import SpecialLayout from './components/Specials/Layout';
import DroogteLandbouw from './components/Specials/DroogteLandbouw';

export const paths = {
    home: '/',
    viewer: 'viewer',
    specials: 'droogte',
    droogteLandbouw: 'droogte-landbouw',
};

function App() {
    return (
        <StoreProvider>
            <Routes>
                <Route path={paths.home} element={<Home />} />
                <Route path={paths.viewer} element={<MainView />} />
                <Route path={paths.specials} element={<SpecialLayout />}>
                    <Route index element={<Specials />} />
                    <Route
                        path={paths.droogteLandbouw}
                        element={<DroogteLandbouw />}
                    />
                </Route>
            </Routes>
        </StoreProvider>
    );
}

export default App;
