/**
 * Page 'Uitgelichte gebeurtenissen'
 */
import styled from 'styled-components';
import BlockLink from './BlockLink';
import imgZeeland from '../../assets/storyline-zeeland.jpg';
import { PageTitle } from './Layout';
import { paths } from '../../App';

const Flexbox = styled.div`
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
`;

const Ref = styled.span`
    font-size: 80%;
`;

const BlockImg = styled.img`
    max-width: 100%;
    float: bottom;
    margin-top: 1em;
`;

const Specials = () => {
    return (
        <div>
            <PageTitle>Impact van droogte</PageTitle>
            <div>
                <p>
                    Waar schade door hevige neerslag over het algemeen direct en
                    lokaal optreedt (bijvoorbeeld in de vorm van ondergelopen
                    straten of huizen) is schade door droogte vaak minder vast
                    omlijnd in tijd en plaats. De impact van droogte treft met
                    name de landbouw in de vorm van verloren of verminderde
                    opbrengst van gewassen.
                </p>
                <p>
                    Op deze pagina vind je nadere informatie over schade door
                    droogte in de landbouw in de vorm van verschillende
                    overzichten of storylines. De meesten worden aangeboden als
                    pdf bestand die hier kunnen worden bekeken of gedownload.
                </p>
                <Flexbox style={{ marginTop: 48 }}>
                    <BlockLink
                        to={paths.droogteLandbouw}
                        title="Droogte in de landbouw"
                    >
                        Inleiding bij de storylines over droogte in de landbouw
                        {/* <BlockImg src={imgZeeland} /> */}
                    </BlockLink>
                    <BlockLink
                        href="/documents/storyline-zeeland.pdf"
                        title="Storyline Zeeland"
                    >
                        Een overzicht van de schade voor de landbouw veroorzaakt
                        door droogte in de jaren 2017-2022 in de regio Zeeland.
                        <BlockImg src={imgZeeland} />
                    </BlockLink>
                </Flexbox>
            </div>
        </div>
    );
};

export default Specials;
