import { useContext, useEffect, useCallback } from 'react';
import MapContext from '../OpenLayers/Map/MapContext';

const ZoomToLocation = ({ location }) => {
    const { map } = useContext(MapContext);

    const zoomToExtent = useCallback((view, feature) => {
        const geom = feature.getGeometry();
        view.fit(geom, { padding: [30, 30, 30, 30] });
    }, []);

    useEffect(() => {
        if (!map || !location) return;
        const view = map.getView();
        zoomToExtent(view, location);
    }, [map, location]);
    return null;
};

export default ZoomToLocation;
