import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const Link = styled(RRLink)`
    position: absolute;
    top: 4px;
    right: 16px;
    color: #6397dd;
    font-size: 24px;

    &:hover {
        color: #003f5c;
    }
`;

const HomeButton = () => {
    return (
        <Link to="/">
            <FaHome />
        </Link>
    );
};

export default HomeButton;
