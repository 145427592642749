import styled from 'styled-components';
import { isset } from '../../helpers/general';

const Label = styled.div`
    font-size: 0.9rem;
    color: #333;
`;

const formatterBelow10 = new Intl.NumberFormat('nl-NL', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
});

const formatValue = (val) => {
    if (val < 10) {
        return formatterBelow10.format(val);
    }
    return Math.round(val);
};

const RainfallTooltipContent = ({ value }) => {
    if (!isset(value) || isNaN(value)) return null;

    return (
        <div>
            <Label>neerslag op dit punt:</Label>
            {formatValue(value)} mm
        </div>
    );
};

export default RainfallTooltipContent;
