import {
    createContext,
    useReducer,
    useContext,
    useEffect,
    useMemo,
} from 'react';
import initialState from './initialState';
import reducer from './reducer';
import * as actions from './actions';
import { fetchCentroids, fetchYearRange } from '../services/backend';
import { eventTypes, mainEvents } from '../services/events';
import GeoJSON from 'ol/format/GeoJSON';
import featureFilter from './featureFilter';
import useImpactsData from '../services/useImpactsData';
import useLocationImpactsData from '../services/useLocationImpactsData';

export { actions };
export { busyStates } from './constants.js';

const StoreContext = createContext();

export const StoreProvider = (props) => {
    const [store, dispatch] = useReducer(reducer, initialState);
    const {
        data: impacts,
        setFilters,
        fetchMore,
        fetching,
    } = useImpactsData(initialState.filters);
    const {
        data: selectedLocationImpacts,
        setLocationId: setSelectedLocation,
        fetching: fetchingSelectedLocation,
    } = useLocationImpactsData();

    useEffect(() => {
        setFilters(store.filters);
    }, [store.filters, setFilters]);

    useEffect(() => {
        setSelectedLocation(store.selectedEventLocation.eventLocationId);
    }, [setSelectedLocation, store.selectedEventLocation.eventLocationId]);

    /**
     * Computed values (derived state)
     *
     * NB: I add these to the store values returned from the context
     * This is a selfmade pattern which seem to work very well, but I
     * can not guarantee that it has no side-effects...
     *
     */
    const geoJsonFeatures = useMemo(() => {
        if (store.centroids) {
            // console.log('Read features', store.centroids);
            const feat = new GeoJSON().readFeatures(store.centroids);
            return feat;
        } else {
            return [];
        }
    }, [store.centroids]);

    const geoJsonFeaturesFiltered = useMemo(() => {
        if (!geoJsonFeatures) {
            return geoJsonFeatures;
        }

        return geoJsonFeatures.filter((feat) =>
            featureFilter({
                feature: feat,
                mainFilter: store.filters.main,
                mainEvent: store.filters.mainEvent,
                locationFilters: store.filters.location,
                eventFilters: store.filters.eventTypes,
                dateFilterFrom: store.filters.date.from,
                dateFilterTo: store.filters.date.to,
                geojson: true,
            })
        );
    }, [
        store.filters.main,
        store.filters.mainEvent,
        store.filters.location,
        store.filters.eventTypes,
        store.filters.date.from,
        store.filters.date.to,
        geoJsonFeatures,
    ]);

    /** Async actions */

    /** Initialize */
    useEffect(() => {
        let fetch;

        fetch = fetchCentroids();
        fetch
            .then((data) => {
                // console.log('fetchCentroids data:', data);
                // for testing default value checkbox: data.inputs.chp.default = true;
                dispatch(actions.setStoreParameter('centroids', data));
            })
            .catch((err) => {
                console.error('err:', err);
            });

        const callFetchYears = fetchYearRange()
            .then((data) => {
                dispatch(
                    actions.setStoreParameter('config', (prev) => ({
                        ...prev,
                        dateRange: {
                            from: new Date(data.minyear, 0, 1),
                            to: new Date(data.maxyear, 11, 31),
                        },
                    }))
                );
                dispatch(
                    actions.setFilterDateFrom(new Date(data.minyear, 0, 1))
                );
                dispatch(
                    actions.setFilterDateTo(new Date(data.maxyear, 11, 31))
                );
            })
            .catch((err) => {
                console.error('err:', err);
            });

        dispatch(
            actions.setFilterEventTypes(eventTypes.map((evt) => evt.value))
        );

        dispatch(actions.setFilterMainEvent(mainEvents[0]));

        return () => {
            fetch?.abort?.();
            callFetchYears?.abort?.();
        };
    }, []);

    return (
        <StoreContext.Provider
            value={{
                store: {
                    ...store,
                    geoJsonFeatures,
                    geoJsonFeaturesFiltered,
                    impacts,
                    selectedLocationImpacts,
                    fetching: store.selectedEventLocation.eventLocationId
                        ? fetchingSelectedLocation
                        : fetching,
                },
                dispatch,
                fetchMoreImpacts: store.selectedEventLocation.eventLocationId
                    ? null
                    : fetchMore,
                asyncActions: {},
            }}
            {...props}
        />
    );
};

/**
 * @typedef {import('./initialState.js').TStore} TStore
 *
 */
/**
 * @typedef {Object} TComputedValues
 * @property {array} geoJsonFeatures - array of parsed GeoJSON feature objects
 * @property {array} geoJsonFeaturesFiltered - filtered array of GeoJSON feature objects
 */
/**
 * @typedef {TStore & TComputedValues} TExtendedStore
 */
/**
 * @typedef {Object} TStoreContext
 * @property {TExtendedStore} store
 * @property {*} impacts
 * @property {*} selectedLocationImpacts
 * @property {boolean} fetching
 * @property {function} dispatch
 * @property {Object} asyncActions
 * @ property {function} asyncActions.loadLocations
 * @ property {function} asyncActions.loadMoreLocations
 * @ property {function} asyncActions.loadMoreImpacts
 */
/**
 *
 * @returns {TStoreContext}
 */
const useStore = () => useContext(StoreContext);

export default useStore;
