import styled from 'styled-components';
import { isEmpty } from '../../helpers/general';
import { parseImpactLocation, getDateString } from '../../helpers/data';
import Kenmerk from './Kenmerk';

const ContentContainer = styled.div``;

const ExternalLink = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})``;

const Plaats = ({ impact }) => {
    if (!impact.location) {
        return null;
    }

    const parsedLocation = parseImpactLocation(impact);
    return (
        <Kenmerk label={parsedLocation.label} content={parsedLocation.value} />
    );
};

const ImpactPopupContent = ({ impact, impactId, loading }) => {
    if (loading) {
        return <div>Loading...</div>;
    }

    if (!impact) {
        return <div>Niet gevonden (impactId: {impactId})</div>;
    }
    const { location } = impact;

    return (
        <ContentContainer>
            {impact.location && (
                <>
                    <Kenmerk label="type" content={location.type} />
                    <Plaats impact={impact} />
                    <Kenmerk
                        label={['locatie', 'locaties']}
                        content={location?.locatie}
                        split
                    />
                    <hr />
                </>
            )}
            <Kenmerk label="datum" content={getDateString(impact)} />
            <Kenmerk label="duur" content={impact.duur} />
            <Kenmerk label="bron" content={impact.bron} />
            <Kenmerk label="impact" content={impact.impact} />
            <Kenmerk
                label={['omschrijving', 'omschrijvingen']}
                content={impact.omschrijving}
                split
            />
            <Kenmerk label="informatie" content={impact.informatie} />
            <Kenmerk
                label="hoeveelheid"
                content={
                    isEmpty(impact.hoeveelheid)
                        ? null
                        : `${impact.hoeveelheid} ${impact.eenheid}`
                }
            />
            <Kenmerk
                label={['link', 'links']}
                content={impact.link}
                split
                format={(lnk) => (
                    <ExternalLink key={lnk} href={lnk}>
                        {lnk}
                    </ExternalLink>
                )}
            />
        </ContentContainer>
    );
};

export default ImpactPopupContent;
