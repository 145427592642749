import styled from 'styled-components';
import { water } from '../../style/colors';

const MainContainer = styled.div`
    display: flex;
`;
const OptionButton = styled.div`
    border: 1px solid ${water};
    border-bottom: none;
    padding: 4px 16px;
    background: ${(props) => (props.active ? water : `#fff`)};
    color: ${(props) => (props.active ? `#fff` : water)};
    user-select: none;
    cursor: ${(props) => (props.active ? `default` : `pointer`)};
`;

const MainSwitch = ({ value, onChange, options = [] }) => {
    const clickHandler = (option) => () => onChange(option.value);

    return (
        <MainContainer>
            {options.map((option) => (
                <OptionButton
                    key={option.value}
                    active={option.value === value}
                    onClick={clickHandler(option)}
                >
                    {option.label}
                </OptionButton>
            ))}
        </MainContainer>
    );
};

export default MainSwitch;
