import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { unByKey } from 'ol/Observable';
import MapContext from '../Map/MapContext';

/**
 * Version: used the code from LGN, but modified: removed the swipe support
 */
const OnMousemoveEvent = ({
    layerSource,
    layerFeatureInfoUrl,
    layerName,
    onMove,
    onData,
    onError,
    onMouseOver,
    onMouseOut,
    getFeatureInfo = true,
}) => {
    const { map } = useContext(MapContext);

    const onMoveRef = useRef();
    const onDataRef = useRef();
    const onErrorRef = useRef();
    const onMouseOverRef = useRef();
    const onMouseOutRef = useRef();

    const eventId = useRef();
    const controllerRef = useRef();

    useEffect(() => {
        onMoveRef.current = onMove;
    }, [onMove]);

    useEffect(() => {
        onDataRef.current = onData;
    }, [onData]);

    useEffect(() => {
        onErrorRef.current = onError;
    }, [onError]);

    useEffect(() => {
        onMouseOverRef.current = onMouseOver;
    }, [onMouseOver]);

    useEffect(() => {
        const mouseOver = function (...args) {
            onMouseOverRef.current?.(...args);
        };
        map.getViewport().addEventListener('mouseover', mouseOver, false);

        return () => {
            if (map && mouseOver) {
                map.getViewport()?.removeEventListener(
                    'mouseover',
                    mouseOver,
                    false
                );
            }
        };
    }, [map]);

    useEffect(() => {
        onMouseOutRef.current = onMouseOut;
    }, [onMouseOut]);

    useEffect(() => {
        const mouseOut = function () {
            onMouseOutRef.current?.();
        };
        map.getViewport().addEventListener('mouseout', mouseOut, false);

        return () => {
            if (map && mouseOut) {
                map.getViewport()?.removeEventListener(
                    'mouseout',
                    mouseOut,
                    false
                );
            }
        };
    }, [map]);

    useEffect(() => {
        let mounted = true;
        let debounceHandler;
        if (!map) return;

        let clickHandler;
        const moveHandler = map.on('pointermove', function (evt) {
            const functionClickId = Date.now();
            eventId.current = functionClickId;
            // eventFinalization = onEventFinalRef.current;
            // const { shiftKey, ctrlKey } = evt.originalEvent;
            // const add = shiftKey || ctrlKey;
            const { coordinate } = evt;
            const mousePosition = {
                x: evt.originalEvent.offsetX,
                y: evt.originalEvent.offsetY,
            };
            const mapProjection = map.getView().getProjection().getCode();
            const viewResolution = map.getView().getResolution();

            onMoveRef.current?.({ mousePosition, coordinate });

            if (getFeatureInfo) {
                let url;
                if (layerFeatureInfoUrl) {
                    // for wmts services we use the layerFeatureInfoUrl wms for getting
                    // the feature info
                    const mapSize = map.getSize();
                    const bbox = map.getView().calculateExtent(mapSize);
                    const pixel = evt.pixel.map((p) => Math.round(p));

                    const infoUrl = layerFeatureInfoUrl;
                    url = `${infoUrl}CRS=${mapProjection}&WIDTH=${
                        mapSize[0]
                    }&HEIGHT=${mapSize[1]}&BBOX=${bbox.join(',')}&I=${
                        pixel[0]
                    }&J=${pixel[1]}`;
                } else {
                    // for wms services we can use the getFeatureInfoUrl function of the source
                    // to construct the call to get the feature info:
                    url = layerSource?.getFeatureInfoUrl?.(
                        coordinate,
                        viewResolution,
                        mapProjection,
                        {
                            INFO_FORMAT: 'application/json',
                            query_layers: layerName,
                        }
                    );
                }
                if (url) {
                    if (controllerRef.current) {
                        controllerRef.current.abort();
                    }
                    controllerRef.current = new AbortController();
                    const signal = controllerRef.current.signal;

                    const fetchData = () => {
                        fetch(url, { signal })
                            .then((response) => response.json())
                            .then((featureInfo) => {
                                if (mounted) {
                                    // Only invoke callback when there has not been a new click in the meantime
                                    // and the click event is still active:
                                    if (eventId.current === functionClickId) {
                                        onDataRef.current?.({
                                            data: featureInfo,
                                            coordinate,
                                        });
                                        if (clickHandler) {
                                            unByKey(clickHandler);
                                        }
                                    }
                                }
                            })
                            .catch((error) => {
                                if (error.name === 'AbortError') {
                                    // We know it's been canceled!
                                } else {
                                    console.warn(error);
                                    onErrorRef.current?.(error);
                                }
                            });
                    };

                    /**
                     * We delay (debounce) the fetch call to limit the number of subsequent calls
                     * But when the user clicks the map we execute immediately
                     */
                    if (debounceHandler) {
                        clearTimeout(debounceHandler);
                    }
                    debounceHandler = setTimeout(fetchData, 300);

                    if (clickHandler) {
                        unByKey(clickHandler);
                    }
                    clickHandler = map.on('singleclick', function (evt) {
                        if (debounceHandler) {
                            clearTimeout(debounceHandler);
                            fetchData();
                        }
                    });
                }
            }
        });

        return () => {
            if (moveHandler) {
                unByKey(moveHandler);
            }
            if (clickHandler) {
                unByKey(clickHandler);
            }
            if (debounceHandler) {
                clearTimeout(debounceHandler);
            }
        };
    }, [map, layerSource, layerName, getFeatureInfo, layerFeatureInfoUrl]);

    return null;
};

OnMousemoveEvent.propTypes = {
    layerSource: PropTypes.shape({
        getFeatureInfoUrl: PropTypes.func,
    }).isRequired, // The layerSource (Layer source) on which the action has effect
    layerFeatureInfoUrl: PropTypes.string,
    layerName: PropTypes.string, // will be added to feature request as query_layers
    onMove: PropTypes.func, // callback invoked directly on mouse move
    // params: { mousePosition, coordinate }
    onData: PropTypes.func, // callback invoked when data is received
    // params: {data, coordinate}
    onError: PropTypes.func, // callback invoked when an error occured
    // params: error
    onMouseOut: PropTypes.func, // callback invoked when the pointer leaves the map
    getFeatureInfo: PropTypes.bool, // default = true,
};

export default OnMousemoveEvent;
