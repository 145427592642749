import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import droogte from '../../assets/droogte.png';
import { blueGreen } from '../../style/colors';

const MainContainer = styled.div`
    width: 100%;
    min-height: 100%;
    overflow: auto;

    &::after {
        content: '';
        background: url(${droogte});
        background-size: cover;
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }
`;

const Spacer = styled.div`
    max-width: 1300px;
    margin: 0 auto;
`;

const ContentContainer = styled.div`
    margin: 16px;
    background: #ffffffdd;
    padding: 16px 10% 48px 5%;
    border-radius: 16px;
    min-height: 50vh;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);

    & > p {
        line-height: 1.6;
        margin-block-end: 2em;
    }
`;

const KIMTitle = styled.a`
    text-decoration: none;
    color: inherit;
    color: #666;
`;

export const PageTitle = styled.h1`
    margin-top: 0;
    color: ${blueGreen};
`;

const SpecialLayout = ({ children, title }) => {
    return (
        <MainContainer>
            <Spacer>
                <ContentContainer>
                    <KIMTitle href="/">Klimaat Impact Monitor</KIMTitle>
                    {children}
                    <Outlet />
                </ContentContainer>
            </Spacer>
        </MainContainer>
    );
};
export default SpecialLayout;
