import { useState, useCallback, useLayoutEffect } from 'react';

/**
 * Usage:
 * const elRef = useRef(null);
 * const [width, height] = useDimensions(elRef);
 * ...
 *   <div ref={elRef}>...</div>
 * ...
 *
 * @typedef {number[]} TDimensionResponse - [width, height]
 *
 * @typedef {Object} TExtendedDimensionResponse
 * @property {TDimensionResponse} dimensions
 * @property {Object} scrollDimensions
 * @property {number} scrollDimensions.scrollHeight
 * @property {number} scrollDimensions.offsetHeight
 * @property {number} scrollDimensions.scrollTop
 *
 * @param {*} elRef
 * @param {boolean} extended - Also return scroll dimensions
 * @returns {TDimensionResponse | TExtendedDimensionResponse}
 */
export default function useDimensions(elRef, extended = false) {
    /** [width, height] */
    const [dimensions, setDimensions] = useState([null, null]);
    const [scrollDimensions, setScrollDimensions] = useState(null);

    const handleResize = useCallback(
        (entries) => {
            if (!Array.isArray(entries)) {
                return;
            }

            const entry = entries[0];
            // console.log('handleResize');
            setDimensions([
                entry?.contentRect?.width,
                entry?.contentRect?.height,
            ]);
            if (extended) {
                setScrollDimensions({
                    scrollHeight: entry?.target?.scrollHeight,
                    offsetHeight: entry?.target?.offsetHeight,
                    scrollTop: entry?.target?.scrollTop,
                });
            }
        },
        [extended]
    );

    useLayoutEffect(() => {
        if (!elRef.current) {
            return;
        }

        let RO = new ResizeObserver(handleResize);
        RO.observe(elRef.current);

        return () => {
            RO.disconnect();
            RO = null;
        };
    }, [elRef, handleResize]);

    if (extended) {
        return { dimensions, scrollDimensions };
    }
    /** [width, height] */
    return dimensions;
}
