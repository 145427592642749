import styled from 'styled-components';
import Fieldset from '../Input/Fieldset';
import Select from '../Input/Select';
import useStore, { actions } from '../../store/useStore';
import { provences, municipalities } from '../../services/location';
import { eventTypes, eventTypeColors } from '../../services/events';
import ChipSelect from './ChipSelect';
import { getLocationGeometry } from '../../services/location';
import DatePicker from '../Input/DatePicker';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 4px 16px;
`;

const TypeLegendItem = styled.div`
    display: inline-block;
    width: 1em;
    height: 1em;
    background: ${(props) => props.color};
    border-radius: 50%;
    vertical-align: bottom;
    border: 1px solid black;
`;

const DataFilters = () => {
    const { store, dispatch } = useStore();

    const provenceOptions = provences;

    const municipalityOptions = municipalities;

    const handleChangeProvence = (opt) => {
        if (opt) {
            getLocationGeometry({ provence: opt.provinciecodePV }).then((r) => {
                dispatch(actions.setFilterGeometry(r));
            });
        } else {
            dispatch(actions.setFilterGeometry(null));
        }
        dispatch(actions.setFilterLocation({ provence: opt, put: false }));
    };

    const handleChangeMunicipality = (opt) => {
        if (opt) {
            getLocationGeometry({
                municipalityCode: opt.value,
            }).then((r) => {
                dispatch(actions.setFilterGeometry(r));
            });
        } else {
            dispatch(actions.setFilterGeometry(null));
        }
        dispatch(actions.setFilterLocation({ municipality: opt, put: true }));
    };

    const handleToggleEvent = (eventTypeValue) => {
        dispatch(actions.toggleFilterEventType(eventTypeValue));
    };

    const handleChangeDateFrom = (newDate) => {
        dispatch(actions.setFilterDateFrom(newDate));
    };
    const handleChangeDateTo = (newDate) => {
        dispatch(actions.setFilterDateTo(newDate));
    };

    return (
        <div>
            <Fieldset label="Type gebeurtenis">
                <ChipSelect
                    options={eventTypes.map((tp) => ({
                        ...tp,
                        label: (
                            <span>
                                {tp.label}{' '}
                                <TypeLegendItem
                                    color={eventTypeColors[tp.value]}
                                />
                            </span>
                        ),
                    }))}
                    values={store.filters.eventTypes}
                    toggleOption={handleToggleEvent}
                />
            </Fieldset>
            <Fieldset label="Locatie">
                <Grid>
                    <Select
                        label="provincie"
                        options={provenceOptions}
                        // style={{ width: 300 }}
                        value={store.filters.location.provence}
                        onChange={handleChangeProvence}
                        isClearable
                    />
                    <div>
                        {Boolean(store.filters.location.provence) && (
                            <Select
                                label="gemeente"
                                options={municipalityOptions.filter(
                                    (mun) =>
                                        mun.provence ===
                                        store.filters.location.provence.value
                                )}
                                // style={{ width: 300 }}
                                value={store.filters.location.municipality}
                                onChange={handleChangeMunicipality}
                                isClearable
                            />
                        )}
                    </div>
                </Grid>
            </Fieldset>
            <Fieldset label="Datum">
                <div style={{ display: 'flex' }}>
                    <div>
                        <DatePicker
                            label="van"
                            selected={store.filters.date.from}
                            onChange={handleChangeDateFrom}
                            minDate={store.config.dateRange.from}
                            maxDate={store.config.dateRange.to}
                        />
                    </div>
                    <div>
                        <DatePicker
                            style={{ marginLeft: 16 }}
                            label="tot"
                            selected={store.filters.date.to}
                            onChange={handleChangeDateTo}
                            minDate={store.config.dateRange.from}
                            maxDate={store.config.dateRange.to}
                        />
                    </div>
                </div>
            </Fieldset>
        </div>
    );
};
export default DataFilters;
