import { isset, isEmpty } from '../helpers/general';
import { dateAsString } from '../helpers/data';

const backendUrl = '/kimserver/';
// test url: const url = 'https://reqres.in/api/users?delay=6';

export class CancelablePromise extends Promise {
    constructor(executor, abortController) {
        super((resolve, reject) => {
            return executor(resolve, reject);
        });
        this.abortController = abortController;
    }

    abort() {
        return this.abortController?.abort?.();
    }

    // isAborted() {
    //     return this.abortController?.signal.aborted;
    // }
}

export const getData = (endpoint, data = {}, bodyData) => {
    const controller = new AbortController();
    const signal = controller.signal;
    return new CancelablePromise((resolve, reject) => {
        let url = `${backendUrl}${
            endpoint.startsWith('/') || endpoint.startsWith('\\')
                ? endpoint.slice(1)
                : endpoint
        }`;

        if (data) {
            const query = new URLSearchParams(data);
            url = `${url}?${query}`;
        }

        const options = { signal };

        if (bodyData) {
            options.method = 'POST';
            // options.headers = {
            //     'Content-Type': 'multipart/form-data',
            //     // 'Content-Type': 'application/x-www-form-urlencoded',
            // };
            // options.body = JSON.stringify(bodyData);
            const formData = new FormData();
            for (const name in bodyData) {
                formData.append(name, bodyData[name]);
            }
            options.body = formData;
        }

        fetch(url, options)
            .then((response) => {
                // console.group();
                // console.log('endpoint:', endpoint, data, bodyData);
                // console.log('response:', response);
                // console.groupEnd();
                // (response.status >= 200 && response.status < 300)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Fetch error');
            })
            .then((responseData) => {
                // console.group();
                // console.log('endpoint:', endpoint, data, bodyData);
                // console.log('success responseData:', responseData);
                // console.groupEnd();
                resolve(responseData);
            })
            .catch((err) => {
                if (err instanceof DOMException && err.name === 'AbortError') {
                    // reject(null);
                    return;
                }
                reject(err);
            });
    }, controller);
};

export const fetchCentroids = () => {
    return getData('geojson/centroids');
};

/**
 * @typedef {import('./events').TEventType} TEventType
 *
 * @typedef {Object} TLocation
 * @property {number} eventlocationid -
 * @property {TEventType} type - ie "Hevige neerslag"
 * @property {string} groep - ie "Droogte 2022"
 * @property {number} jaar -
 * @property {string} datum - yyyy-mm-dd
 * @property {number} duur -
 * @property {string} provinciecode - province code
 * @property {string} province - province full name
 * @property {string} gemeente -
 * @property {string} plaats -
 * @property {string} locatie -
 * @property {string} impact - What happened
 * @property {string} informatie -
 * @property {string} bron - concatenated array of source types
 *
 * NOT USED
 * @param {Object} filters
 * @param {*} filters.eventType - drought, heavy rain, flood,
 * @param {string} filters.provence - provinciecode
 * @param {string} filters.group - main event
 * @param {number} filters.pagesize (10)
 * @param {number} filters.pagenumber (1)
 * @returns {Promise<Error|TLocation[]>}
 */
export const fetchLocations = (filters = {}) => {
    const {
        eventType,
        provence,
        group,
        pagesize = 10,
        pagenumber = 1,
    } = filters;
    const queryParams = {};

    if (isset(eventType)) {
        queryParams.par1 = eventType;
    }
    if (isset(provence)) {
        queryParams.par2 = provence;
    }
    if (isset(group)) {
        queryParams.par4 = group;
    }
    if (isset(pagesize)) {
        queryParams.pagesize = pagesize;
    }
    if (isset(pagenumber)) {
        queryParams.pagenumber = pagenumber;
    }

    return getData('json/locations', queryParams).then(
        (result) => result.items
    );
};

/**
 * @typedef {Object} TImpact
 * @property {number} id -
 * @property {number} eventlocationid -
 * @property {string} impact - What happened
 * @property {number} jaar -
 * @property {string} datum - yyyy-mm-dd
 * @property {number} duur -
 * @property {string} omschrijving -
 * @property {string} informatie -
 * @property {string} hoeveelheid -
 * @property {string} eenheid -
 * @property {string} bron - source types
 * @property {string} link -
 * @property {TLocation} location - the parent location of the impact
 *
 * NOT USED
 * @param {Object} filters
 * @param {number} filters.eventlocationid
 * @param {number} filters.pagesize (10)
 * @param {number} filters.pagenumber (1) *
 * @returns {Promise<Error|TImpact[]>}
 */
export const fetchImpacts = (filters = {}) => {
    const { eventlocationid, pagesize = 10, pagenumber = 1 } = filters;
    const queryParams = {};

    if (isset(eventlocationid)) {
        queryParams.par1 = eventlocationid;
    }
    if (isset(pagesize)) {
        queryParams.pagesize = pagesize;
    }
    if (isset(pagenumber)) {
        queryParams.pagenumber = pagenumber;
    }

    return getData('json/impacts', queryParams).then((result) => result.items);
};

/**
 * @typedef {Object} TImpact
 * @property {number} id -
 * @property {number} eventlocationid -
 * @property {string} impact - What happened
 * @property {number} jaar -
 * @property {string} datum - yyyy-mm-dd
 * @property {number} duur -
 * @property {string} omschrijving -
 * @property {string} informatie -
 * @property {string} hoeveelheid -
 * @property {string} eenheid -
 * @property {string} bron - source types
 * @property {string} link -
 * @property {TLocation} location - the parent location of the impact
 *
 * @param {number} eventlocationid
 * @returns {Promise<Error|TImpact>}
 */
export const fetchImpactWithLocation = (eventlocationid) => {
    const queryParams = { par1: eventlocationid };

    return getData('json/impacts', queryParams).then((result) => {
        const [impact] = result.items;
        if (!impact) {
            return null;
        }
        /** @todo get location. This cannot be done yet, because there is no filter for it in the API */
        return { ...impact, location: null };
    });
};

/**
 * @typedef {Object} TImpactFilters
 * @property {string} locationId
 * @property {string} group
 * @property {string[]} eventTypes
 * @property {string} provenceCode
 * @property {string} municipality
 * @property {Date} dateFrom
 * @property {Date} dateTo
 * @property {number} pagesize (10)
 * @property {number} pagenumber (1)
 */
/**
 * @param {TImpactFilters} filters - filter on loactionId or groep or a combination of type, location, date, etc.
 * @returns {Promise<Error|TImpact[]>}
 */
export const fetchLocationImpacts = (filters = {}) => {
    const {
        locationId,
        group,
        eventTypes,
        provenceCode,
        municipality,
        dateFrom,
        dateTo,
        pagesize = 10,
        pagenumber = 1,
    } = filters;
    const queryParams = {};
    if (isset(locationId)) {
        queryParams.location_id = locationId;
    } else {
        if (isset(group)) {
            queryParams.groep = group;
        }
        if (!isEmpty(eventTypes)) {
            queryParams.type = eventTypes.join(';');
        }
        if (isset(provenceCode)) {
            queryParams.provinciecode = provenceCode;
        }
        if (isset(municipality)) {
            queryParams.gemeente = municipality;
        }
        if (!isEmpty(dateFrom)) {
            queryParams.startdatum_van = dateAsString(dateFrom);
        }
        if (!isEmpty(dateTo)) {
            queryParams.einddatum_tot = dateAsString(dateTo);
        }
        if (isset(pagesize)) {
            queryParams.pagesize = pagesize;
        }
        if (isset(pagenumber)) {
            queryParams.pagenumber = pagenumber;
        }
    }
    return getData('locationimpact', queryParams).then(
        (result) => result.locations
    );
};

/**
 * @param {TImpactFilters} filters
 * @returns {Promise<Error|TImpact[]>}
 */
export const fetchImpactsWithLocation = (filters = {}) => {
    return fetchLocationImpacts(filters).then((locations) => {
        return locations.reduce((prev, loc) => {
            const impacts = loc.impacts.map((imp) => ({
                ...imp,
                location: loc,
            }));
            return [...prev, ...impacts];
        }, []);
    });
};

/**
 * @typedef {{minyear: number, maxyear: number}} TYearRangeResult
 * @returns {Promise<Error|TYearRangeResult>}
 */
export const fetchYearRange = () => {
    return getData('json/years_with_data').then((result) => {
        const [output] = result.items;
        return output;
    });
};

// /**
//  * @param {Object} filters
//  * @param {*} filters.eventType
//  * @param {string} filters.provence
//  * @param {number} filters.year
//  * @param {string} filters.group
//  * @param {number} filters.pagesize (10)
//  * @param {number} filters.pagenumber (1)
//  * @returns {Promise<Error|TImpact[]>}
//  */
// export const fetchImpactsFromLocations = (filters = {}) => {
//     return fetchLocations(filters)
//         .then((locations) => {
//             return Promise.all(
//                 locations.map((loc) =>
//                     fetchLocationImpacts({
//                         locationId: loc.eventlocationid,
//                     }).then((results) => {
//                         const [locationWithImpact] = results;
//                         return locationWithImpact;
//                     })
//                 )
//             );
//         })
//         .then((result) => {
//             return result.reduce((prev, loc) => {
//                 const { impacts, ...location } = loc;
//                 const extImpacts =
//                     impacts?.map((impact) => ({
//                         ...impact,
//                         location,
//                     })) || [];
//                 return [...prev, ...extImpacts];
//             }, []);
//         });
// };

// export const fetchDummy = () => {
//     return new CancelablePromise(
//         (resolve, reject) => {
//             resolve({});
//         },
//         { abort: () => {} }
//     );
// };
