import { useContext, useEffect, useState } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import { vector, cluster } from '../Source';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';

let styleCache = {};
const defaultStyle = function (feature) {
    const size = feature.get('features').length;
    let currentStyle = styleCache[size];

    if (!currentStyle) {
        currentStyle = new Style({
            image: new CircleStyle({
                radius: 10,
                stroke: new Stroke({
                    color: '#fff',
                }),
                fill: new Fill({
                    color: '#3399CC',
                }),
            }),
            text: new Text({
                text: size.toString(),
                fill: new Fill({
                    color: '#fff',
                }),
            }),
        });
        styleCache[size] = currentStyle;
    }
    return currentStyle;
};

const VectorLayer = ({
    children = [],
    style = defaultStyle,
    zIndex = 0,
    opacity = 1,
    clustered = false,
    distance = 40,
    minDistance = 5,
    name,
}) => {
    const { map } = useContext(MapContext);
    const [olLayer, setOlLayer] = useState();
    const [source] = useState(vector({}));
    const [clusterSource] = useState(
        cluster({ source, distance, minDistance })
    );

    useEffect(() => {
        if (!map) return;

        let vectorLayer;
        if (clustered) {
            vectorLayer = new OLVectorLayer({
                source: clusterSource,
                style,
            });
        } else {
            vectorLayer = new OLVectorLayer({
                source,
                style,
            });
        }

        map.addLayer(vectorLayer);
        // vectorLayer.setZIndex(zIndex);
        setOlLayer(vectorLayer);

        return () => {
            if (map) {
                map.removeLayer(vectorLayer);
            }
        };
    }, [map, source, clusterSource, clustered, style]);

    // useEffect(() => {
    //     if (!map || !olLayer) return;

    //     olLayer.setSource(source);
    // }, [map, source]);

    useEffect(() => {
        if (!olLayer) return;

        olLayer.set('name', name);
    }, [olLayer, name]);

    useEffect(() => {
        if (!map || !olLayer) return;

        olLayer.setZIndex(zIndex);
    }, [map, zIndex, olLayer]);

    useEffect(() => {
        if (!map || !olLayer) return;

        olLayer.setOpacity(opacity);
    }, [map, opacity, olLayer]);

    useEffect(() => {
        if (!clusterSource) return;
        clusterSource.setDistance(distance);
    }, [clusterSource, distance]);

    useEffect(() => {
        if (!clusterSource) return;
        clusterSource.setMinDistance(minDistance);
    }, [clusterSource, minDistance]);

    useEffect(() => {
        children.forEach((child) => {
            if (Array.isArray(child)) {
                source.addFeatures(child);
            } else if (child) {
                source.addFeature(child);
            }
        });

        return () => {
            children.forEach((child) => {
                if (Array.isArray(child)) {
                    child.forEach((f) => source.removeFeature(f));
                } else if (child) {
                    source.removeFeature(child);
                }
            });
        };
    }, [children, source]);

    return null;
};

export default VectorLayer;
